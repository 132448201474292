import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';

import { useForm, SubmitHandler } from 'react-hook-form';

import VerifyEmailCard from './VerifyEmailCard';
import CreateUserForm from './CreateUserForm';
import axios from '../../axios';
import { CreateUserFormInput } from './CreateUserForm';
import MainDialog from '../../components/MainDialog';
import { snakeCase, mapKeys } from 'lodash';
import { AxiosError } from 'axios';
import { LinearProgress } from '@mui/material';
import { ErrorResponseData, errorTypesMap } from '../../utils/errorHelpers';

export default function SignUpPage() {
  const [submitted, setSubmitted] = useState(false);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState<CreateUserFormInput | undefined>();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');
  const { control, handleSubmit } = useForm<CreateUserFormInput>();

  const onSubmit: SubmitHandler<CreateUserFormInput> = async (
    data: CreateUserFormInput
  ) => {
    setIsLoading(true);
    setFormData(data);
    try {
      await axios('sanctum/csrf-cookie');
      await axios.post(
        'api/auth/register',
        mapKeys(data, (v, k) => snakeCase(k))
      );
      setSubmitted(true);
    } catch (error) {
      const err = error as AxiosError;
      const errorData: ErrorResponseData = err.response
        ?.data as ErrorResponseData;
      if (
        err.response?.status === 409 &&
        errorData?.error.type === errorTypesMap.EMAIL_ALREADY_TAKEN
      ) {
        setDialogContent(t('SIGNUP.EMAIL_TAKEN'));
        setIsDialogOpen(true);
      } else if (err.response?.status === 409) {
        setDialogContent(t('SIGNUP.VALIDATION_ERROR'));
        setIsDialogOpen(true);
      } else {
        setDialogContent(t('MAIN.SERVER_ERROR'));
        setIsDialogOpen(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '90vh',
          padding: '12px',
        }}
      >
        {isLoading && (
          <LinearProgress
            sx={{ position: 'absolute', top: '0px', width: '100%' }}
          />
        )}
        {submitted ? (
          <VerifyEmailCard email={formData?.email} />
        ) : (
          <CreateUserForm
            control={control}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            isLoading={isLoading}
          />
        )}
      </Box>
      <MainDialog
        open={isDialogOpen}
        onConfirm={() => {
          setIsDialogOpen(false);
        }}
        content={dialogContent}
        ok={t('SIGNUP.OK')}
      />
    </>
  );
}
