import {
  Box,
  Button,
  Card,
  CardContent,
  Skeleton,
  Snackbar,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from '../../../axios';
import { snakeCase, mapKeys, camelCase, pickBy, sortBy } from 'lodash';
import { useAuth } from '../../../context/AuthContext';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useConfirm } from 'material-ui-confirm';
import { Customer } from '../../../models/customer.model';
import TaskDetailsForm from './TaskDetailsForm';
import { useHistory, useParams } from 'react-router-dom';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { Task } from '../../../models/task.model';
import { Team } from '../../../models/team.model';
import Attachments from './Attachments';
import doesRoleExist from '../../../utils/doesRoleExist';
import { useNotification } from '../../../context/NotificationContext';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={isMobile ? { paddingTop: 3 } : { p: 3 }}>{children}</Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    'data-testid': `simple-tab-${index}`,
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function TaskDetailsPage() {
  const { t } = useTranslation();
  const theme = useTheme();
  const history = useHistory();
  let { taskId }: { taskId: string } = useParams();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [task, setTask] = useState<Task>();
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const { organization, user } = useAuth();
  const confirm = useConfirm();
  const [isLoading, setIsLoading] = useState(false);
  const [isDetailsLoading, setIsDetailsLoading] = useState(false);
  const [value, setValue] = React.useState(0);

  const [customers, setCustomers] = useState<Customer[]>([]);
  const [teams, setTeams] = useState<Team[]>([]);
  const { showMessage } = useNotification();

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    const fetchData = async () => {
      const customersResp = await axios.get(
        `api/organizations/${organization?.id}/customers`
      );
      setCustomers(
        sortBy(
          customersResp.data.data.items.map((item: any) => {
            const customerObj = mapKeys(item, (_v, k) => camelCase(k));
            const customer = new Customer();
            return customer.deserialize(customerObj);
          }),
          ['shortName']
        )
      );

      const teamsResp = await axios.get(
        `api/organizations/${organization?.id}/teams`
      );
      const teamsData = teamsResp.data.data.items.map((item: any) => {
        item.manager = mapKeys(item.manager, (_v, k) => camelCase(k));
        return mapKeys(item, (_v, k) => camelCase(k));
      });
      setTeams(
        teamsData.map((item: any) => {
          const teamObj = mapKeys(item, (_v, k) => camelCase(k));
          const team = new Team();
          return team.deserialize(teamObj);
        })
      );
    };
    fetchData();
  }, [organization?.id]);

  const fetchTask = useCallback(
    async (taskId: string) => {
      setIsLoading(true);
      const taskResp = await axios.get(
        `api/organizations/${organization?.id}/tasks/${taskId}`
      );
      const task = new Task();
      task.deserialize(mapKeys(taskResp.data.data, (_v, k) => camelCase(k)));
      setTask(task);
      setIsLoading(false);
    },
    [organization?.id]
  );

  useEffect(() => {
    fetchTask(taskId);
  }, [fetchTask, taskId]);

  const deleteCustomer = () => {
    confirm({
      title: t('CONFIRMATION.ARE_YOU_SURE'),
      description: t('CONFIRMATION.DESCRIPTION'),
      confirmationText: t('CONFIRMATION.YES'),
      cancellationText: t('CONFIRMATION.CANCEL'),
    })
      .then(async () => {
        await axios.delete(
          `api/organizations/${organization?.id}/tasks/${taskId}`
        );
        history.push('../tasks');

        showMessage({
          message: t('TASK_DETAILS.DELETED'),
        });
      })
      .catch(() => {});
  };

  const handleClose = (
    _event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    /* istanbul ignore next */
    if (reason === 'clickaway') {
      return;
    }

    /* istanbul ignore next */
    setAlertOpen(false);
  };

  useEffect(() => {
    if (value === 1) {
    }
  }, [value]);

  const updateBasicDetails = useCallback(
    async (data: Task) => {
      setIsDetailsLoading(true);
      await axios.put(
        `api/organizations/${organization?.id}/tasks/${data.id}`,
        mapKeys(pickBy(data), (_v, k) => snakeCase(k))
      );
      fetchTask(taskId);
      setIsDetailsLoading(false);
      setAlertMessage('TASK_DETAILS.UPDATED');
      setAlertOpen(true);
    },
    [organization?.id, taskId, fetchTask]
  );

  const detailsCard = (
    <Card sx={{ width: '100%', maxWidth: isMobile ? '100%' : '75%' }}>
      <CardContent>
        <Typography
          variant="h6"
          sx={{
            marginBottom: '20px',
            textAlign: 'left',
            color: '#828080',
          }}
          data-testid="detailsHeader"
        >
          {t('TASK_DETAILS.DETAILS')}
        </Typography>
        <TaskDetailsForm
          task={task}
          onSubmit={updateBasicDetails}
          isDetailsLoading={isDetailsLoading}
          customers={customers}
          teams={teams}
        />
      </CardContent>
    </Card>
  );

  const dangerZoneCard = (
    <Card sx={{ width: '25%', minWidth: isMobile ? '100%' : 400 }}>
      <CardContent>
        <Typography
          variant="h6"
          sx={{
            marginBottom: '20px',
            textAlign: 'left',
            color: '#828080',
          }}
          data-testid="signInHeader"
        >
          {t('SETTINGS.DANGER_ZONE')}
        </Typography>
        <Button
          variant="contained"
          size="medium"
          onClick={deleteCustomer}
          data-testid="deleteTask"
          disabled={
            !doesRoleExist(user, organization, [
              'ADMIN',
              'TEAM_MEMBER',
              'TEAM_MANAGER',
            ])
          }
          sx={{ width: '200px', backgroundColor: 'red', color: 'white' }}
        >
          {t('TASK_DETAILS.DELETE')}
        </Button>
      </CardContent>
    </Card>
  );

  return (
    <Box
      display="flex"
      flexDirection="row"
      gap="20px"
      flexWrap="wrap"
      data-testid="taskDetailsPage"
    >
      <Box>
        <Button
          variant="text"
          data-testid="tasksLink"
          onClick={() => history.push('../tasks')}
        >
          <KeyboardBackspaceIcon /> {t('TASK_DETAILS.GO_BACK')}
        </Button>
        {isLoading ? (
          <Skeleton animation="wave" />
        ) : (
          <Typography
            variant="h5"
            mb={1.5}
            sx={{ color: 'darkgray', paddingLeft: '24px', paddingTop: '12px' }}
          >
            {task?.title}
          </Typography>
        )}
      </Box>

      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="customer-tabs"
          >
            <Tab label={t('TASK_DETAILS.DETAILS')} {...a11yProps(0)} />
            <Tab label={t('TASK_DETAILS.ATTACHMENTS')} {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Box display="flex" flexDirection="column" gap={2}>
            {detailsCard}
            {dangerZoneCard}
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Attachments taskId={taskId} />
        </TabPanel>
      </Box>
      <Snackbar
        open={isAlertOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          sx={{ width: '100%' }}
          data-testid="alertId"
        >
          {t(alertMessage)}
        </Alert>
      </Snackbar>
    </Box>
  );
}
