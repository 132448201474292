import {
  Alert,
  FormHelperText,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import axios from '../../../axios';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSystemSettings } from '../../../context/SystemSettingsContext';
import { WORDS_ONLY_REGEX } from '../../../utils/regexHelpers';
import { useAuth } from '../../../context/AuthContext';
import { Organization } from '../../../models/organization.model';

export interface CreateOrganizationFormInput {
  organizationName: string;
  size: string;
  industry: string;
  currency: string;
}

export default function CreateCompanyForm(props: {
  onSubmit: SubmitHandler<CreateOrganizationFormInput>;
}) {
  const { t } = useTranslation();
  const { industries } = useSystemSettings();
  const theme = useTheme();
  const { organizations } = useAuth();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { control, handleSubmit, formState } =
    useForm<CreateOrganizationFormInput>();

  const { onSubmit } = props;
  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            padding: '10px',
            minWidth: isMobile ? '100%' : '300px',
          }}
        >
          {organizations?.filter((o: Organization) => o?.pivot?.deleted_at)
            ?.length ? (
            <Alert severity="warning" sx={{ mb: 2 }}>
              <p>
                You have been removed from following organizations. Please
                contact with your administrator to get more information.
              </p>
              <ul
                style={{
                  listStyleType: 'disc',
                  paddingLeft: '20px',
                  marginTop: '8px',
                }}
              >
                {organizations?.filter((o: Organization) => o.pivot.deleted_at)
                  .length &&
                  organizations
                    .filter((o: Organization) => o.pivot.deleted_at)
                    .map((o: Organization) => <li id={o.id}>{o.name}</li>)}
              </ul>
            </Alert>
          ) : (
            ''
          )}
          <Typography
            variant="h6"
            sx={{
              marginBottom: '20px',
              textAlign: 'center',
            }}
            data-testid="signInHeader"
          >
            {t('CREATE_ORGANIZATION.TITLE')}
          </Typography>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ display: 'contents' }}
          >
            <Controller
              name="organizationName"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  label={t('CREATE_ORGANIZATION.NAME')}
                  helperText={error ? error.message : null}
                  sx={{
                    marginBottom: '20px',
                  }}
                  inputProps={{ 'data-testid': 'organizationNameInput' }}
                />
              )}
              rules={{
                validate: {
                  required: async (val: string) => {
                    if (!val) {
                      return `${t('CREATE_ORGANIZATION.NAME')} ${t(
                        'SIGNUP.REQUIRED'
                      )}`;
                    }

                    const { data: { data: isExists = false } = {} } =
                      await axios.get('api/organizations/is-exists', {
                        params: {
                          name: val,
                        },
                      });

                    if (isExists === true) {
                      return `${t('CREATE_ORGANIZATION.NAME_EXISTS')}`;
                    }
                  },
                },
                maxLength: {
                  value: 50,
                  message: t('VALIDATION.MAX_LENGTH', { length: 50 }),
                },
                pattern: {
                  value: WORDS_ONLY_REGEX,
                  message: t('VALIDATION.INVALID_VALUE'),
                },
              }}
            />
            <Controller
              name="size"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl
                  fullWidth
                  error={!!error}
                  sx={{
                    marginBottom: '20px',
                  }}
                >
                  <InputLabel id="demo-simple-select-label">
                    {t('CREATE_ORGANIZATION.SIZE')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    data-testid="organizationSizeSelect"
                    value={value}
                    error={!!error}
                    label={t('CREATE_ORGANIZATION.SIZE')}
                    onChange={onChange}
                    inputProps={{ 'data-testid': 'organizationSizeInput' }}
                  >
                    <MenuItem value={'1-10'}>1 - 10</MenuItem>
                    <MenuItem value={'10-50'}>10 - 50</MenuItem>
                    <MenuItem value={'50-100'}>50 - 100</MenuItem>
                    <MenuItem value={'100+'}>100+</MenuItem>
                  </Select>
                  <FormHelperText>
                    {error
                      ? `${t('CREATE_ORGANIZATION.SIZE')} ${t(
                          'SIGNUP.REQUIRED'
                        )}`
                      : ''}
                  </FormHelperText>
                </FormControl>
              )}
              rules={{
                required: `${t('CREATE_ORGANIZATION.SIZE')} ${t(
                  'SIGNUP.REQUIRED'
                )}`,
              }}
            />
            <Controller
              name="industry"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl
                  fullWidth
                  error={!!error}
                  sx={{
                    marginBottom: '20px',
                  }}
                >
                  <InputLabel id="demo-simple-select-label">
                    {t('CREATE_ORGANIZATION.SECTOR')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value}
                    error={!!error}
                    data-testid={'organizationSectorInput'}
                    label={t('CREATE_ORGANIZATION.SECTOR')}
                    onChange={onChange}
                  >
                    {industries?.map((industry: string) => (
                      <MenuItem value={industry} key={industry}>
                        {industry}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {error
                      ? `${t('CREATE_ORGANIZATION.SECTOR')} ${t(
                          'SIGNUP.REQUIRED'
                        )}`
                      : ''}
                  </FormHelperText>
                </FormControl>
              )}
              rules={{
                required: `${t('CREATE_ORGANIZATION.SIZE')} ${t(
                  'SIGNUP.REQUIRED'
                )}`,
              }}
            />

            <Controller
              name="currency"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl
                  fullWidth
                  error={!!error}
                  sx={{
                    marginBottom: '20px',
                  }}
                >
                  <InputLabel id="demo-simple-select-label">
                    {t('CREATE_ORGANIZATION.CURRENCY')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    data-testid="organizationSizeSelect"
                    value={value}
                    error={!!error}
                    label={t('CREATE_ORGANIZATION.CURRENCY')}
                    onChange={onChange}
                    inputProps={{ 'data-testid': 'organizationSizeInput' }}
                  >
                    <MenuItem value="TRY">TRY</MenuItem>
                    <MenuItem value="USD">USD</MenuItem>
                    <MenuItem value="EUR">EUR</MenuItem>
                  </Select>
                  <FormHelperText>
                    {error
                      ? `${t('CREATE_ORGANIZATION.CURRENCY')} ${t(
                          'SIGNUP.REQUIRED'
                        )}`
                      : ''}
                  </FormHelperText>
                </FormControl>
              )}
              rules={{
                required: `${t('CREATE_ORGANIZATION.CURRENCY')} ${t(
                  'SIGNUP.REQUIRED'
                )}`,
              }}
            />

            <Button
              type="submit"
              variant="contained"
              disabled={formState.isSubmitting}
              data-testid="createOrganizationSubmit"
              sx={{
                marginTop: '20px',
              }}
            >
              {t('CREATE_ORGANIZATION.SAVE')}
            </Button>
          </form>
        </Box>
      </CardContent>
    </Card>
  );
}
