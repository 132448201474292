import { Delete } from '@mui/icons-material';
import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { DesktopDateTimePicker } from '@mui/x-date-pickers';
import { get } from 'lodash';
import { useEffect } from 'react';
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
  useWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  orderStatusTextMap,
  useSystemSettings,
} from '../../../context/SystemSettingsContext';
import { Customer } from '../../../models/customer.model';
import { Product } from '../../../models/product.model';
import { AddOrderItemInput } from './AddOrderItemDialog';

export interface AddOrderInput {
  customerId: string;
  orderDate: string;
  totalAmount: string;
  totalDiscount: string;
  taxAmount: string;
  note: string;
  status: string;
  items: AddOrderItemInput[];
}

export default function AddOrderDialog({
  isOpen,
  handleClose,
  onSubmit,
  customers,
  products,
}: {
  isOpen: boolean;
  handleClose: () => void;
  onSubmit: SubmitHandler<AddOrderInput>;
  customers: Customer[];
  products: Product[];
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { orderStatusTypes } = useSystemSettings();

  const { reset, control, handleSubmit, getValues, setValue } =
    useForm<AddOrderInput>();

  const {
    fields: items,
    remove: removeItem,
    append: appendItem,
  } = useFieldArray({
    name: 'items',
    control,
  });

  const getAmounts = (orderItems: AddOrderItemInput[] = []) => {
    const amounts = orderItems.reduce(
      (mem, i) => {
        if (i.totalAmount) {
          mem.totalAmount += parseFloat(i.totalAmount);
        }

        if (i.taxAmount) {
          mem.taxAmount += parseFloat(i.taxAmount);
        }

        return mem;
      },
      {
        totalAmount: 0,
        taxAmount: 0,
      }
    );

    parseFloat(amounts.totalAmount.toFixed(2));
    parseFloat(amounts.taxAmount.toFixed(2));
    return amounts;
  };

  const orderItems = useWatch({ control, name: 'items' }) || [];
  const amounts = getAmounts(orderItems);

  if (
    amounts.totalAmount &&
    String(amounts.totalAmount) !== getValues('totalAmount')
  ) {
    setValue('totalAmount', String(amounts.totalAmount));
  }

  if (
    amounts.taxAmount &&
    String(amounts.taxAmount) !== getValues('taxAmount')
  ) {
    setValue('taxAmount', String(amounts.taxAmount));
  }

  const discount = useWatch({ control, name: 'totalDiscount' });

  if (discount && getValues('totalAmount')) {
    const newTotalNumber =
      parseFloat(getValues('totalAmount')) - parseFloat(discount);
    const newTotalFixed = newTotalNumber.toFixed(2);
    if (newTotalFixed !== getValues('totalAmount')) {
      setValue('totalAmount', newTotalFixed);
    }
  }

  useEffect(() => {
    if (isOpen) {
      reset({
        customerId: '',
        orderDate: new Date().toDateString(),
        totalAmount: '',
        totalDiscount: '',
        taxAmount: '',
        note: '',
        status: '',
        items: [],
      });
    }
  }, [reset, isOpen]);

  const handleNewOrderItem = () => {
    appendItem({
      productId: '',
      quantity: '',
      price: '',
      taxRate: '',
      taxAmount: '',
      totalAmount: '',
    });
  };

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <form style={{ display: 'contents' }}>
        <Dialog
          maxWidth={'xl'}
          open={isOpen}
          onClose={handleClose}
          disableEscapeKeyDown
        >
          <DialogTitle>{t('ORDERS.CREATE')}</DialogTitle>
          <DialogContent
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignContent: 'flex-start',
              gap: '8px',
              flexWrap: 'wrap',
            }}
          >
            <Controller
              name="customerId"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl
                  error={!!error}
                  sx={{
                    marginTop: '20px',
                    minWidth: '210px',
                    width: isMobile ? '100%' : '45%',
                  }}
                >
                  <InputLabel id="demo-simple-select-label">
                    {t('ORDERS.CUSTOMER')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value}
                    error={!!error}
                    label={t('ORDERS.CUSTOMER')}
                    data-testid={'customerInput'}
                    onChange={onChange}
                  >
                    {customers?.map((customer: Customer) => (
                      <MenuItem value={customer.id} key={customer.id}>
                        {customer.shortName}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {error
                      ? `${t('ORDERS.CUSTOMER')} ${t('ORDERS.REQUIRED')}`
                      : ''}
                  </FormHelperText>
                </FormControl>
              )}
              rules={{
                required: `${t('ORDERS.CUSTOMER')} ${t('ORDERS.REQUIRED')}`,
              }}
            />
            <Controller
              name="orderDate"
              control={control}
              defaultValue={new Date().toDateString()}
              render={({ field: { ref, onChange, value, ...field } }) => (
                <DesktopDateTimePicker
                  {...field}
                  value={value}
                  inputRef={ref}
                  label={t('ORDERS.ORDER_DATE')}
                  inputFormat="dd/MM/yyyy hh:mm aa"
                  onChange={onChange}
                  renderInput={(inputProps) => (
                    <TextField
                      {...inputProps}
                      margin="normal"
                      sx={{
                        width: isMobile ? '100%' : '45%',
                      }}
                    />
                  )}
                />
              )}
              rules={{
                required: `${t('ORDERS.ORDER_DATE')} ${t('ORDERS.REQUIRED')}`,
              }}
            />
            <Controller
              name="totalAmount"
              control={control}
              defaultValue={''}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  multiline
                  disabled
                  label={t('ORDERS.PRICE')}
                  helperText={error ? error.message : null}
                  sx={{
                    marginTop: '20px',
                    width: isMobile ? '100%' : '45%',
                  }}
                  inputProps={{ 'data-testid': 'totalAmountInput' }}
                />
              )}
              rules={{
                pattern: {
                  value: /^[+-]?\d+(\.\d+)?$/,
                  message: `${t('VALIDATION.INVALID_NUMBER_FORMAT')}`,
                },
              }}
            />
            <Controller
              name="totalDiscount"
              control={control}
              defaultValue={''}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  multiline
                  label={t('ORDERS.DISCOUNT')}
                  helperText={error ? error.message : null}
                  sx={{
                    marginTop: '20px',
                    width: isMobile ? '100%' : '45%',
                  }}
                  inputProps={{ 'data-testid': 'totalDiscountInput' }}
                />
              )}
              rules={{
                pattern: {
                  value: /^[+-]?\d+(\.\d+)?$/,
                  message: `${t('VALIDATION.INVALID_NUMBER_FORMAT')}`,
                },
              }}
            />
            <Controller
              name="taxAmount"
              control={control}
              defaultValue={''}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  multiline
                  disabled
                  label={t('ORDERS.TAX')}
                  helperText={error ? error.message : null}
                  sx={{
                    marginTop: '20px',
                    width: isMobile ? '100%' : '45%',
                  }}
                  inputProps={{ 'data-testid': 'taxAmountInput' }}
                />
              )}
              rules={{
                pattern: {
                  value: /^[+-]?\d+(\.\d+)?$/,
                  message: `${t('VALIDATION.INVALID_NUMBER_FORMAT')}`,
                },
              }}
            />

            <Controller
              name="status"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl
                  error={!!error}
                  sx={{
                    marginTop: '20px',
                    minWidth: '210px',
                    width: isMobile ? '100%' : '45%',
                  }}
                >
                  <InputLabel id="demo-simple-select-label">
                    {t('ORDERS.STATUS')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value}
                    error={!!error}
                    label={t('ORDERS.STATUS')}
                    onChange={onChange}
                    data-testid={'statusInput'}
                  >
                    {orderStatusTypes.map((orderStatus: string) => (
                      <MenuItem value={orderStatus} key={orderStatus}>
                        {t(get(orderStatusTextMap, orderStatus))}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {error
                      ? `${t('ORDERS.STATUS')} ${t('ORDERS.REQUIRED')}`
                      : ''}
                  </FormHelperText>
                </FormControl>
              )}
              rules={{
                required: `${t('ORDERS.STATUS')} ${t('ORDERS.REQUIRED')}`,
              }}
            />

            <Controller
              name="note"
              control={control}
              defaultValue={''}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  rows={3}
                  multiline
                  label={t('ORDERS.NOTE')}
                  helperText={error ? error.message : null}
                  sx={{
                    marginBottom: '20px',
                    marginTop: '20px',
                    width: isMobile ? '100%' : '45%',
                  }}
                  inputProps={{ 'data-testid': 'noteInput' }}
                />
              )}
            />

            <Box sx={{ width: '100%' }}>
              <Button
                sx={{ marginBottom: 2 }}
                onClick={() => handleNewOrderItem()}
                variant="text"
              >
                {t('ORDERS.ADD_ITEM')}{' '}
              </Button>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('ORDER_ITEMS.PRODUCT')}</TableCell>
                      <TableCell>{t('ORDER_ITEMS.QUANTITY')}</TableCell>
                      <TableCell>{t('ORDER_ITEMS.PRICE')}</TableCell>
                      <TableCell>{t('ORDER_ITEMS.TAX_RATE')}</TableCell>
                      <TableCell>{t('ORDER_ITEMS.TAX_AMOUNT')}</TableCell>
                      <TableCell>{t('ORDER_ITEMS.TOTAL_AMOUNT')}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(items || []).map(
                      (item: AddOrderItemInput, index: number) => {
                        const key = `order-item-${index}`;
                        return (
                          <TableRow key={key}>
                            <TableCell>
                              <Controller
                                name={`items.${index}.productId`}
                                control={control}
                                defaultValue={item.productId}
                                render={({
                                  field: { value, onChange },
                                  fieldState: { error },
                                }) => (
                                  <FormControl
                                    error={!!error}
                                    sx={{
                                      marginTop: '20px',
                                      minWidth: '210px',
                                      width: isMobile ? '100%' : '45%',
                                    }}
                                  >
                                    <Select
                                      value={value}
                                      error={!!error}
                                      onChange={(event) => {
                                        onChange(event);
                                        const product = products.find(
                                          (p: Product) => {
                                            return p.id === event.target.value;
                                          }
                                        );

                                        setValue(
                                          `items.${index}.product`,
                                          product
                                        );

                                        setValue(
                                          `items.${index}.price`,
                                          product?.unitPrice || ''
                                        );

                                        const quantity = 1;
                                        setValue(
                                          `items.${index}.quantity`,
                                          `${quantity}`
                                        );

                                        const newPrice: number =
                                          quantity *
                                          parseFloat(product?.unitPrice || '0');

                                        const taxRate =
                                          parseFloat(
                                            getValues(
                                              `items.${index}.taxRate`
                                            ) || '0'
                                          ) / 100;

                                        const taxAmount: number =
                                          taxRate * newPrice;

                                        if (taxAmount) {
                                          setValue(
                                            `items.${index}.taxAmount`,
                                            String(taxAmount)
                                          );
                                        }

                                        const newTotalAmount: number =
                                          newPrice + taxAmount;

                                        setValue(
                                          `items.${index}.totalAmount`,
                                          String(newTotalAmount || '0')
                                        );
                                      }}
                                    >
                                      {products?.map((product: Product) => (
                                        <MenuItem
                                          value={product.id}
                                          key={product.id}
                                        >
                                          {product.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                    <FormHelperText>
                                      {error
                                        ? `${t('ORDER_ITEMS.PRODUCT')} ${t(
                                            'ORDER_ITEMS.REQUIRED'
                                          )}`
                                        : ''}
                                    </FormHelperText>
                                  </FormControl>
                                )}
                                rules={{
                                  required: `${t('ORDER_ITEMS.PRODUCT')} ${t(
                                    'ORDER_ITEMS.REQUIRED'
                                  )}`,
                                }}
                              />
                            </TableCell>
                            <TableCell>
                              <Controller
                                name={`items.${index}.quantity`}
                                control={control}
                                defaultValue={''}
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => (
                                  <TextField
                                    value={value}
                                    onChange={(event) => {
                                      onChange(event);

                                      const product = getValues(
                                        `items.${index}.product`
                                      );

                                      const quantity: number = parseFloat(
                                        event.target.value || '1'
                                      );

                                      const newPrice: number =
                                        quantity *
                                        parseFloat(product?.unitPrice || '0');

                                      const taxRate =
                                        parseFloat(
                                          getValues(`items.${index}.taxRate`) ||
                                            '0'
                                        ) / 100;
                                      const taxAmount: number =
                                        taxRate * newPrice;

                                      if (taxAmount) {
                                        setValue(
                                          `items.${index}.taxAmount`,
                                          String(taxAmount.toFixed(2))
                                        );
                                      }

                                      const newTotalAmount: number =
                                        newPrice + taxAmount;

                                      setValue(
                                        `items.${index}.price`,
                                        String(newPrice) || ''
                                      );
                                      setValue(
                                        `items.${index}.totalAmount`,
                                        String(newTotalAmount || '0')
                                      );
                                    }}
                                    error={!!error}
                                    multiline
                                    label={t('ORDER_ITEMS.QUANTITY')}
                                    helperText={error ? error.message : null}
                                    sx={{
                                      marginTop: '20px',
                                      width: isMobile ? '100%' : '45%',
                                    }}
                                    inputProps={{
                                      'data-testid': 'quantityInput',
                                    }}
                                  />
                                )}
                                rules={{
                                  required: `${t('ORDER_ITEMS.QUANTITY')} ${t(
                                    'ORDER_ITEMS.REQUIRED'
                                  )}`,
                                }}
                              />
                            </TableCell>

                            <TableCell>
                              <Controller
                                name={`items.${index}.price`}
                                control={control}
                                defaultValue={''}
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => (
                                  <TextField
                                    value={value}
                                    disabled
                                    onChange={(event) => {
                                      onChange(event);

                                      const newPrice: number = parseFloat(
                                        event.target.value || '0'
                                      );

                                      const taxRate =
                                        parseFloat(
                                          getValues(`items.${index}.taxRate`) ||
                                            '0'
                                        ) / 100;

                                      const taxAmount: number =
                                        taxRate * newPrice;

                                      if (taxAmount) {
                                        setValue(
                                          `items.${index}.taxAmount`,
                                          String(taxAmount)
                                        );
                                      }

                                      const newTotalAmount: number =
                                        newPrice + taxAmount;
                                      setValue(
                                        `items.${index}.totalAmount`,
                                        String(newTotalAmount || '0')
                                      );
                                    }}
                                    error={!!error}
                                    multiline
                                    label={t('ORDER_ITEMS.PRICE')}
                                    helperText={error ? error.message : null}
                                    sx={{
                                      marginTop: '20px',
                                      width: isMobile ? '100%' : '45%',
                                    }}
                                    inputProps={{ 'data-testid': 'priceInput' }}
                                  />
                                )}
                                rules={{
                                  required: `${t('ORDER_ITEMS.PRICE')} ${t(
                                    'ORDER_ITEMS.REQUIRED'
                                  )}`,
                                  pattern: {
                                    value: /^[+-]?\d+(\.\d+)?$/,
                                    message: `${t(
                                      'VALIDATION.INVALID_NUMBER_FORMAT'
                                    )}`,
                                  },
                                  maxLength: {
                                    value: 50,
                                    message: t('VALIDATION.MAX_LENGTH', {
                                      length: 50,
                                    }),
                                  },
                                }}
                              />
                            </TableCell>

                            <TableCell>
                              <Controller
                                name={`items.${index}.taxRate`}
                                control={control}
                                defaultValue={''}
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => (
                                  <TextField
                                    value={value}
                                    onChange={(event) => {
                                      onChange(event);

                                      const taxRate =
                                        (parseFloat(event.target.value) || 0) /
                                        100;
                                      const taxAmount: number =
                                        parseFloat(
                                          getValues(`items.${index}.price`)
                                        ) * taxRate;

                                      const totalAmount: number =
                                        taxAmount +
                                        parseFloat(
                                          getValues(`items.${index}.price`)
                                        );

                                      setValue(
                                        `items.${index}.taxAmount`,
                                        String(taxAmount.toFixed(2))
                                      );
                                      setValue(
                                        `items.${index}.totalAmount`,
                                        String(totalAmount.toFixed(2))
                                      );
                                    }}
                                    error={!!error}
                                    multiline
                                    label={t('ORDER_ITEMS.TAX_RATE')}
                                    helperText={error ? error.message : null}
                                    sx={{
                                      marginTop: '20px',
                                      width: isMobile ? '100%' : '45%',
                                    }}
                                    inputProps={{
                                      'data-testid': 'taxRateInput',
                                    }}
                                  />
                                )}
                                rules={{
                                  required: `${t('ORDER_ITEMS.TAX_RATE')} ${t(
                                    'ORDER_ITEMS.REQUIRED'
                                  )}`,
                                  pattern: {
                                    value: /^[+-]?\d+(\.\d+)?$/,
                                    message: `${t(
                                      'VALIDATION.INVALID_NUMBER_FORMAT'
                                    )}`,
                                  },
                                  maxLength: {
                                    value: 50,
                                    message: t('VALIDATION.MAX_LENGTH', {
                                      length: 50,
                                    }),
                                  },
                                }}
                              />
                            </TableCell>
                            <TableCell>
                              <Controller
                                name={`items.${index}.taxAmount`}
                                control={control}
                                defaultValue={''}
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => (
                                  <TextField
                                    value={value}
                                    onChange={onChange}
                                    disabled
                                    error={!!error}
                                    multiline
                                    label={t('ORDER_ITEMS.TAX_AMOUNT')}
                                    helperText={error ? error.message : null}
                                    sx={{
                                      marginTop: '20px',
                                      width: isMobile ? '100%' : '45%',
                                    }}
                                    inputProps={{
                                      'data-testid': 'taxAmountInput',
                                    }}
                                  />
                                )}
                                rules={{
                                  required: `${t('ORDER_ITEMS.TAX_AMOUNT')} ${t(
                                    'ORDER_ITEMS.REQUIRED'
                                  )}`,
                                  pattern: {
                                    value: /^[+-]?\d+(\.\d+)?$/,
                                    message: `${t(
                                      'VALIDATION.INVALID_NUMBER_FORMAT'
                                    )}`,
                                  },
                                  maxLength: {
                                    value: 50,
                                    message: t('VALIDATION.MAX_LENGTH', {
                                      length: 50,
                                    }),
                                  },
                                }}
                              />
                            </TableCell>
                            <TableCell>
                              <Controller
                                name={`items.${index}.totalAmount`}
                                control={control}
                                defaultValue={''}
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => (
                                  <TextField
                                    value={value}
                                    disabled
                                    onChange={onChange}
                                    error={!!error}
                                    multiline
                                    label={t('ORDER_ITEMS.TOTAL_AMOUNT')}
                                    helperText={error ? error.message : null}
                                    sx={{
                                      marginTop: '20px',
                                      width: isMobile ? '100%' : '45%',
                                    }}
                                    inputProps={{
                                      'data-testid': 'totalAmountInput',
                                    }}
                                  />
                                )}
                                rules={{
                                  required: `${t(
                                    'ORDER_ITEMS.TOTAL_AMOUNT'
                                  )} ${t('ORDER_ITEMS.REQUIRED')}`,
                                  pattern: {
                                    value: /^[+-]?\d+(\.\d+)?$/,
                                    message: `${t(
                                      'VALIDATION.INVALID_NUMBER_FORMAT'
                                    )}`,
                                  },
                                  maxLength: {
                                    value: 50,
                                    message: t('VALIDATION.MAX_LENGTH', {
                                      length: 50,
                                    }),
                                  },
                                }}
                              />
                            </TableCell>
                            <TableCell align="right">
                              <IconButton
                                onClick={() => removeItem(index)}
                                aria-label="delete"
                              >
                                <Delete />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} data-testid="cancelDialog">
              {t('CUSTOMERS.CUSTOMER_DETAILS.ACTIVITY_CANCEL')}
            </Button>
            <Button
              disabled={!orderItems.length}
              onClick={handleSubmit(onSubmit)}
            >
              {t('CUSTOMERS.CUSTOMER_DETAILS.ACTIVITY_SAVE')}
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </Box>
  );
}
