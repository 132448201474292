import {
  Box,
  Button,
  Card,
  CardContent,
  Skeleton,
  Snackbar,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from '../../../axios';
import { snakeCase, mapKeys, camelCase, pickBy } from 'lodash';
import { useAuth } from '../../../context/AuthContext';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useConfirm } from 'material-ui-confirm';
import ProductDetailsForm from './ProductDetailsForm';
import { useHistory, useParams } from 'react-router-dom';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { Product } from '../../../models/product.model';
import { Category } from '../../../models/category.model';
import doesRoleExist from '../../../utils/doesRoleExist';
import { useNotification } from '../../../context/NotificationContext';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={isMobile ? { paddingTop: 3 } : { p: 3 }}>{children}</Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    'data-testid': `simple-tab-${index}`,
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function ProductDetailsPage() {
  const { t } = useTranslation();
  const theme = useTheme();
  const history = useHistory();
  let { productId }: { productId: string } = useParams();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [product, setProduct] = useState<Product>();
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const { organization, user } = useAuth();
  const confirm = useConfirm();
  const [isLoading, setIsLoading] = useState(false);
  const [isDetailsLoading, setIsDetailsLoading] = useState(false);
  const [value, setValue] = React.useState(0);
  const [categories, setCategories] = useState<Category[]>([]);

  const { showMessage } = useNotification();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const fetchProduct = useCallback(
    async (productId: string) => {
      setIsLoading(true);
      const productResp = await axios.get(
        `api/organizations/${organization?.id}/products/${productId}`
      );
      const product = new Product();
      product.deserialize(
        mapKeys(productResp.data.data, (v, k) => camelCase(k))
      );
      setProduct(product);
      setIsLoading(false);
    },
    [organization?.id]
  );

  const fetchCategories = useCallback(async () => {
    const categoriesResp = await axios.get(
      `api/organizations/${organization?.id}/categories`
    );
    const categories = categoriesResp.data.data.items.map((item: Category) => {
      const category = new Category();
      const categoryObj = mapKeys(item, (v, k) => camelCase(k));
      return category.deserialize(categoryObj);
    });
    setCategories(categories);
  }, [organization?.id]);

  useEffect(() => {
    fetchProduct(productId);
    fetchCategories();
  }, [fetchProduct, productId, fetchCategories]);

  const deleteProduct = () => {
    confirm({
      title: t('CONFIRMATION.ARE_YOU_SURE'),
      description: t('CONFIRMATION.DESCRIPTION'),
      confirmationText: t('CONFIRMATION.YES'),
      cancellationText: t('CONFIRMATION.CANCEL'),
    })
      .then(async () => {
        await axios.delete(
          `api/organizations/${organization?.id}/products/${productId}`
        );

        showMessage({
          message: t('PRODUCTS.DELETED'),
        });
        history.push('../products');
      })
      .catch(() => {});
  };

  const handleClose = (
    _event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    /* istanbul ignore next */
    if (reason === 'clickaway') {
      return;
    }

    /* istanbul ignore next */
    setAlertOpen(false);
  };

  useEffect(() => {
    if (value === 1) {
    }
  }, [value]);

  const updateBasicDetails = useCallback(
    async (data: Product) => {
      setIsDetailsLoading(true);
      const dataObj = pickBy(data);
      if (!dataObj.isActive) {
        dataObj.isActive = false;
      }
      await axios.put(
        `api/organizations/${organization?.id}/products/${data.id}`,
        mapKeys(dataObj, (v, k) => snakeCase(k))
      );
      fetchProduct(data.id);
      setIsDetailsLoading(false);
      setAlertMessage('PRODUCTS.UPDATED');
      setAlertOpen(true);
    },
    [organization?.id, fetchProduct]
  );

  const detailsCard = (
    <Card sx={{ width: '100%', maxWidth: isMobile ? '100%' : '75%' }}>
      <CardContent>
        <Typography
          variant="h6"
          sx={{
            marginBottom: '20px',
            textAlign: 'left',
            color: '#828080',
          }}
          data-testid="detailsHeader"
        >
          {t('PRODUCT.DETAILS')}
        </Typography>
        <ProductDetailsForm
          product={product}
          onSubmit={updateBasicDetails}
          isDetailsLoading={isDetailsLoading}
          categories={categories}
        />
      </CardContent>
    </Card>
  );

  const dangerZoneCard = (
    <Card sx={{ width: '25%', minWidth: isMobile ? '100%' : 400 }}>
      <CardContent>
        <Typography
          variant="h6"
          sx={{
            marginBottom: '20px',
            textAlign: 'left',
            color: '#828080',
          }}
          data-testid="signInHeader"
        >
          {t('SETTINGS.DANGER_ZONE')}
        </Typography>
        <Button
          variant="contained"
          size="medium"
          onClick={deleteProduct}
          data-testid="deleteProduct"
          disabled={!doesRoleExist(user, organization, ['ADMIN'])}
          sx={{ width: '200px', backgroundColor: 'red', color: 'white' }}
        >
          {t('PRODUCT.DELETE')}
        </Button>
      </CardContent>
    </Card>
  );

  return (
    <Box
      display="flex"
      flexDirection="row"
      gap="20px"
      flexWrap="wrap"
      data-testid="productDetailsPage"
    >
      <Box>
        <Button
          variant="text"
          data-testid="productsLink"
          onClick={() => history.push('../products')}
        >
          <KeyboardBackspaceIcon /> {t('PRODUCT_DETAILS.GO_BACK')}
        </Button>
        {isLoading ? (
          <Skeleton animation="wave" />
        ) : (
          <Typography
            variant="h5"
            mb={1.5}
            sx={{ color: 'darkgray', paddingLeft: '24px', paddingTop: '12px' }}
          >
            {product?.name}
          </Typography>
        )}
      </Box>

      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="customer-tabs"
          >
            <Tab label={t('PRODUCT.DETAILS')} {...a11yProps(0)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Box display="flex" flexDirection="column" gap={2}>
            {detailsCard}
            {dangerZoneCard}
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}></TabPanel>
      </Box>
      <Snackbar
        open={isAlertOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          sx={{ width: '100%' }}
          data-testid="alertId"
        >
          {t(alertMessage)}
        </Alert>
      </Snackbar>
    </Box>
  );
}
