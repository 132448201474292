import React, { useEffect } from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import { get, times } from 'lodash';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { getComparator, Order } from '../../../utils/tableHelpers';
import { roleNameMap } from '../../../utils/roleNameMap';
import { Skeleton } from '@mui/material';
import { useAuth } from '../../../context/AuthContext';
import doesRoleExist from '../../../utils/doesRoleExist';
import { useConfirm } from 'material-ui-confirm';
import { useNotification } from '../../../context/NotificationContext';

export interface Data {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string[];
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'USERS.USER_ID',
  },
  {
    id: 'firstName',
    numeric: false,
    disablePadding: false,
    label: 'USERS.NAME',
  },
  {
    id: 'lastName',
    numeric: false,
    disablePadding: false,
    label: 'USERS.LASTNAME',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'USERS.EMAIL',
  },
  {
    id: 'role',
    numeric: false,
    disablePadding: false,
    label: 'USERS.ROLES',
  },
];

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {t(headCell.label)}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
  name: string;
  userId: string;
  deleteUser: (userId: string) => void;
  openChangeRoleDialog: () => void;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { numSelected, name, userId, deleteUser, openChangeRoleDialog } = props;
  const { t } = useTranslation();
  const confirm = useConfirm();
  const { showMessage } = useNotification();

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {name} {t('USERS.SELECTED')}
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {t('USERS.LINK')}
        </Typography>
      )}
      {numSelected > 0 && (
        <>
          <Tooltip title={t('USERS.CHANGE_DETAILS')}>
            <IconButton onClick={openChangeRoleDialog} data-testid="changeRole">
              <ManageAccountsIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('USERS.DELETE_USER')}>
            <IconButton
              onClick={() =>
                confirm({
                  title: t('CONFIRMATION.ARE_YOU_SURE'),
                  description: t('CONFIRMATION.DESCRIPTION'),
                  confirmationText: t('CONFIRMATION.YES'),
                  cancellationText: t('CONFIRMATION.CANCEL'),
                }).then(async () => {
                  deleteUser(userId);

                  showMessage({
                    message: t('USERS.DELETED'),
                  });
                })
              }
              data-testid="deleteUser"
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
    </Toolbar>
  );
}

export default function UsersTable({
  rows,
  deleteUser,
  openChangeRoleDialog,
  isLoading,
  selectedUserId,
  setSelectedUserId,
}: {
  rows: Data[];
  deleteUser: (userId: string) => void;
  openChangeRoleDialog: () => void;
  isLoading: boolean;
  selectedUserId: string;
  setSelectedUserId: (id: string) => void;
}) {
  const [order, setOrder] = React.useState<Order>('asc');
  const { t } = useTranslation();
  const { user, organization } = useAuth();
  const [orderBy, setOrderBy] = React.useState<keyof Data>('firstName');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  useEffect(() => {
    if (!rows.find((row) => row.id === selectedUserId)) {
      setSelectedUserId('');
    }
  }, [rows, selectedUserId, setSelectedUserId]);

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (_event: React.MouseEvent<unknown>, $id: string) => {
    if (doesRoleExist(user, organization, ['ADMIN'])) {
      setSelectedUserId($id === selectedUserId ? '' : $id);
    }
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = ($id: string) => selectedUserId === $id;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  let rowDisplay;
  if (isLoading) {
    rowDisplay = times(3, (index) => {
      return (
        <TableRow key={index}>
          <TableCell padding="checkbox">
            <Skeleton animation="wave" />
          </TableCell>
          <TableCell component="th" scope="row" padding="none">
            <Skeleton animation="wave" />
          </TableCell>
          <TableCell align="left">
            <Skeleton animation="wave" />
          </TableCell>
          <TableCell align="left">
            <Skeleton animation="wave" />
          </TableCell>
          <TableCell align="left">
            <Skeleton animation="wave" />
          </TableCell>
          <TableCell align="left">
            <Skeleton animation="wave" />
          </TableCell>
        </TableRow>
      );
    });
  } else {
    rowDisplay = rows
      .sort(getComparator(order, orderBy))
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((row, index) => {
        const isItemSelected = isSelected(row.id);
        const labelId = `enhanced-table-checkbox-${index}`;

        return (
          <TableRow
            hover
            onClick={(event) => handleClick(event, row.id)}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.id}
            data-testid={row.id}
            selected={isItemSelected}
          >
            <TableCell padding="checkbox">
              {doesRoleExist(user, organization, ['ADMIN']) && (
                <Checkbox
                  color="primary"
                  checked={isItemSelected}
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              )}
            </TableCell>
            <TableCell component="th" id={labelId} scope="row" padding="none">
              {row.id}
            </TableCell>
            <TableCell align="left">{row.firstName}</TableCell>
            <TableCell align="left">{row.lastName}</TableCell>
            <TableCell align="left">{row.email}</TableCell>
            <TableCell align="left">
              {Array.isArray(row.role)
                ? row.role
                    .map((role) => {
                      return t(get(roleNameMap, role));
                    })
                    .join(', ')
                : row.role}
            </TableCell>
          </TableRow>
        );
      });
  }

  return (
    <Box sx={{ width: '100%' }} data-testid="usersTable">
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selectedUserId ? 1 : 0}
          name={rows.find((r) => r.id === selectedUserId)?.firstName || ''}
          userId={selectedUserId}
          deleteUser={deleteUser}
          openChangeRoleDialog={() => openChangeRoleDialog()}
        />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {rowDisplay}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          data-testid="rowsPerPage"
        />
      </Paper>
    </Box>
  );
}
