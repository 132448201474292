import {
  FormHelperText,
  Skeleton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { DesktopDateTimePicker } from '@mui/x-date-pickers';
import { getValue } from '@testing-library/user-event/dist/utils';
import { each, get, times } from 'lodash';
import { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../context/AuthContext';
import {
  orderStatusTextMap,
  useSystemSettings,
} from '../../../context/SystemSettingsContext';
import { Customer } from '../../../models/customer.model';
import { Order } from '../../../models/order.model';
import doesRoleExist from '../../../utils/doesRoleExist';

export default function OrderDetailsForm(props: {
  order?: Order;
  onSubmit: SubmitHandler<Order>;
  isDetailsLoading: boolean;
  customers: Customer[];
}) {
  const { onSubmit, isDetailsLoading, order, customers } = props;
  const { t } = useTranslation();
  const { orderStatusTypes } = useSystemSettings();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { organization, user } = useAuth();

  type formValue =
    | 'id'
    | 'code'
    | 'customerId'
    | 'orderDate'
    | 'status'
    | 'note'
    | 'totalAmount'
    | 'price'
    | 'taxAmount'
    | 'totalDiscount';

  const { control, handleSubmit, setValue } = useForm<Order>();

  useEffect(() => {
    const fillTheValues = async () => {
      if (order) {
        const fields = [
          'id',
          'code',
          'customerId',
          'orderDate',
          'status',
          'note',
          'totalAmount',
          'price',
          'taxAmount',
          'totalDiscount',
        ];

        each(fields, (field) => {
          const value = get(order, field);
          if (value !== null && value !== undefined) {
            setValue(field as formValue, get(order, field));
          }
        });
      }
    };

    fillTheValues();
  }, [order, setValue]);

  if (isDetailsLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          padding: '10px',
          flexWrap: 'wrap',
          gap: '24px',
        }}
      >
        {times(5, (index) => (
          <Skeleton animation="wave" sx={{ marginBottom: 6 }} key={index} />
        ))}
      </Box>
    );
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        justifyContent: 'space-between',
        padding: '10px',
        flexWrap: 'wrap',
        gap: '12px',
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'contents' }}>
        <Controller
          name="code"
          control={control}
          defaultValue={''}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              disabled
              value={value}
              onChange={onChange}
              error={!!error}
              label={t('ORDERS.CODE')}
              helperText={error ? error.message : null}
              sx={{
                marginTop: '20px',
                width: isMobile ? '100%' : '45%',
              }}
              inputProps={{ 'data-testid': 'codeInput' }}
            />
          )}
          rules={{
            required: `${t('ORDERS.CODE')} ${t('ORDERS.REQUIRED')}`,
          }}
        />
        <Controller
          name="customerId"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl
              error={!!error}
              sx={{
                marginTop: '20px',
                minWidth: '210px',
                width: isMobile ? '100%' : '45%',
              }}
            >
              <InputLabel id="demo-simple-select-label">
                {t('ORDERS.CUSTOMER')}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value}
                error={!!error}
                label={t('ORDERS.CUSTOMER')}
                onChange={onChange}
                data-testid={'customerInput'}
                disabled={doesRoleExist(user, organization, ['OBSERVER'])}
              >
                {customers?.map((customer: Customer) => (
                  <MenuItem value={customer.id} key={customer.id}>
                    {customer.shortName}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {error ? `${t('ORDERS.CUSTOMER')} ${t('ORDERS.REQUIRED')}` : ''}
              </FormHelperText>
            </FormControl>
          )}
          rules={{
            required: `${t('ORDERS.CUSTOMER')} ${t('ORDERS.REQUIRED')}`,
          }}
        />
        <Controller
          name="status"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl
              error={!!error}
              sx={{
                marginTop: '20px',
                minWidth: '210px',
                width: isMobile ? '100%' : '45%',
              }}
            >
              <InputLabel id="demo-simple-select-label">
                {t('ORDERS.STATUS')}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value}
                error={!!error}
                label={t('ORDERS.STATUS')}
                onChange={onChange}
                data-testid={'statusInput'}
                disabled={doesRoleExist(user, organization, ['OBSERVER'])}
              >
                {orderStatusTypes?.map((status: string) => (
                  <MenuItem value={status} key={status}>
                    {t(get(orderStatusTextMap, status))}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {error ? `${t('ORDERS.STATUS')} ${t('ORDERS.REQUIRED')}` : ''}
              </FormHelperText>
            </FormControl>
          )}
          rules={{
            required: `${t('ORDERS.STATUS')} ${t('ORDERS.REQUIRED')}`,
          }}
        />
        <Controller
          name="orderDate"
          control={control}
          defaultValue={new Date().toDateString()}
          render={({ field: { ref, onChange, value, ...field } }) => (
            <DesktopDateTimePicker
              {...field}
              value={value}
              inputRef={ref}
              label={t('ORDERS.ORDER_DATE')}
              inputFormat="dd/MM/yyyy hh:mm aa"
              onChange={(value) => {
                onChange(value);
              }}
              disabled={doesRoleExist(user, organization, ['OBSERVER'])}
              renderInput={(inputProps) => (
                <TextField
                  {...inputProps}
                  margin="normal"
                  sx={{
                    width: isMobile ? '100%' : '45%',
                  }}
                />
              )}
            />
          )}
          rules={{
            required: `${t('ORDERS.ORDER_DATE')} ${t('ORDERS.REQUIRED')}`,
          }}
        />
        <Controller
          name="price"
          control={control}
          defaultValue={''}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              onChange={onChange}
              error={!!error}
              multiline
              disabled
              label={t('ORDERS.PRICE')}
              helperText={error ? error.message : null}
              sx={{
                marginTop: '20px',
                width: isMobile ? '100%' : '45%',
              }}
              inputProps={{ 'data-testid': 'price' }}
            />
          )}
        />
        <Controller
          name="taxAmount"
          control={control}
          defaultValue={''}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              onChange={onChange}
              error={!!error}
              multiline
              label={t('ORDERS.TAX')}
              disabled
              helperText={error ? error.message : null}
              sx={{
                marginTop: '20px',
                width: isMobile ? '100%' : '45%',
              }}
              inputProps={{ 'data-testid': 'taxAmountInput' }}
            />
          )}
        />
        <Controller
          name="totalDiscount"
          control={control}
          defaultValue={''}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              onChange={(event) => {
                onChange(event);

                const discount = parseFloat(event.target.value) || 0;
                if (order?.totalAmount) {
                  setValue(
                    'totalAmount',
                    String(parseFloat(order.price) - discount)
                  );
                }
              }}
              onBlur={() => {
                if (!value) {
                  setValue('totalDiscount', '0');
                }
              }}
              error={!!error}
              multiline
              label={t('ORDERS.DISCOUNT')}
              helperText={error ? error.message : null}
              sx={{
                marginTop: '20px',
                width: isMobile ? '100%' : '45%',
              }}
              inputProps={{ 'data-testid': 'totalDiscountInput' }}
              disabled={doesRoleExist(user, organization, ['OBSERVER'])}
            />
          )}
        />
        <Controller
          name="totalAmount"
          control={control}
          defaultValue={''}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              onChange={onChange}
              error={!!error}
              multiline
              disabled
              label={t('ORDER_ITEMS.TOTAL_AMOUNT')}
              helperText={error ? error.message : null}
              sx={{
                marginTop: '20px',
                width: isMobile ? '100%' : '45%',
              }}
              inputProps={{ 'data-testid': 'totalAmountInput' }}
            />
          )}
        />
        <Controller
          name="note"
          control={control}
          defaultValue={''}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              onChange={onChange}
              error={!!error}
              rows={4}
              multiline
              label={t('ORDERS.NOTE')}
              helperText={error ? error.message : null}
              sx={{
                marginBottom: '20px',
                marginTop: '20px',
                width: '100%',
              }}
              inputProps={{ 'data-testid': 'noteInput' }}
              disabled={doesRoleExist(user, organization, ['OBSERVER'])}
            />
          )}
        />
        <Button
          type="submit"
          variant="outlined"
          data-testid="updateOrderSubmit"
          sx={{
            marginTop: '20px',
            maxWidth: '200px',
            minWidth: '160px',
            height: '40px',
            alignSelf: 'flex-end',
          }}
          disabled={
            !doesRoleExist(user, organization, [
              'ADMIN',
              'TEAM_MEMBER',
              'TEAM_MANAGER',
            ])
          }
        >
          {t('ORDERS.UPDATE')}
        </Button>
      </form>
    </Box>
  );
}
