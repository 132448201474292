import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {
  Box,
  Button,
  Card,
  CardContent,
  Skeleton,
  Snackbar,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { camelCase, mapKeys, pickBy, snakeCase, sortBy } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import axios from '../../../axios';
import { useAuth } from '../../../context/AuthContext';
import { useNotification } from '../../../context/NotificationContext';
import { Customer } from '../../../models/customer.model';
import { Order } from '../../../models/order.model';
import doesRoleExist from '../../../utils/doesRoleExist';
import Attachments from './Attachments';
import OrderDetailsForm from './OrderDetailsForm';
import OrderItems from './OrderItems';
import ShareOrderDialog from './ShareOrderDialog';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={isMobile ? { paddingTop: 3 } : { p: 3 }}>{children}</Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    'data-testid': `simple-tab-${index}`,
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function OrderDetailsPage() {
  const { t } = useTranslation();
  const theme = useTheme();
  const history = useHistory();
  let { orderId }: { orderId: string } = useParams();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [order, setOrder] = useState<Order>();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const { organization, user } = useAuth();
  const confirm = useConfirm();
  const [isLoading, setIsLoading] = useState(false);
  const [isDetailsLoading, setIsDetailsLoading] = useState(false);
  const [value, setValue] = React.useState(0);

  const [customers, setCustomers] = useState<Customer[]>([]);

  const { showMessage } = useNotification();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue === 0) {
      fetchOrder(orderId);
    }
    setValue(newValue);
  };

  useEffect(() => {
    const fetchData = async () => {
      const customersResp = await axios.get(
        `api/organizations/${organization?.id}/customers`
      );
      setCustomers(
        sortBy(
          customersResp.data.data.items.map((item: any) => {
            const customerObj = mapKeys(item, (v, k) => camelCase(k));
            const customer = new Customer();
            return customer.deserialize(customerObj);
          }),
          ['shortName']
        )
      );
    };
    fetchData();
  }, [organization?.id]);

  const fetchOrder = useCallback(
    async (orderId: string) => {
      setIsLoading(true);
      const orderResp = await axios.get(
        `api/organizations/${organization?.id}/orders/${orderId}`
      );
      const order = new Order();
      order.deserialize(mapKeys(orderResp.data.data, (v, k) => camelCase(k)));
      setOrder(order);
      setIsLoading(false);
    },
    [organization?.id]
  );

  const sendEmailShare = useCallback(
    async (emails: string[]) => {
      await axios.post(
        `api/organizations/${organization?.id}/orders/${orderId}/share-order-via-email`,
        { to: emails }
      );
      setAlertMessage(t('ORDER_DETAILS.SHARE_ORDER_EMAILS_SENT'));
      setAlertOpen(true);
      setIsDialogOpen(false);
    },
    [orderId, organization?.id, t]
  );

  useEffect(() => {
    fetchOrder(orderId);
  }, [fetchOrder, orderId]);

  const deleteOrder = () => {
    confirm({
      title: t('CONFIRMATION.ARE_YOU_SURE'),
      description: t('CONFIRMATION.DESCRIPTION'),
      confirmationText: t('CONFIRMATION.YES'),
      cancellationText: t('CONFIRMATION.CANCEL'),
    })
      .then(async () => {
        await axios.delete(
          `api/organizations/${organization?.id}/orders/${orderId}`
        );
        history.push('../orders');

        showMessage({
          message: t('ORDERS.ORDER_DELETED'),
        });
      })
      .catch(() => {});
  };

  const handleClose = (
    _event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    /* istanbul ignore next */
    if (reason === 'clickaway') {
      return;
    }

    /* istanbul ignore next */
    setAlertOpen(false);
  };

  useEffect(() => {
    if (value === 1) {
    }
  }, [value]);

  const updateBasicDetails = useCallback(
    async (data: Order) => {
      setIsDetailsLoading(true);
      const payload = mapKeys(pickBy(data), (v, k) => snakeCase(k));

      if (!payload.note) {
        payload.note = '';
      }

      await axios.put(
        `api/organizations/${organization?.id}/orders/${data.id}`,
        payload
      );
      fetchOrder(orderId);
      setIsDetailsLoading(false);
      setAlertMessage('ORDER_DETAILS.UPDATED');
      setAlertOpen(true);
    },
    [organization?.id, orderId, fetchOrder]
  );

  const detailsCard = (
    <Card sx={{ width: '100%', maxWidth: isMobile ? '100%' : '75%' }}>
      <CardContent>
        <Typography
          variant="h6"
          sx={{
            marginBottom: '20px',
            textAlign: 'left',
            color: '#828080',
          }}
          data-testid="detailsHeader"
        >
          {t('ORDER_DETAILS.DETAILS')}
        </Typography>
        <OrderDetailsForm
          order={order}
          onSubmit={updateBasicDetails}
          isDetailsLoading={isDetailsLoading}
          customers={customers}
        />
      </CardContent>
    </Card>
  );

  const dangerZoneCard = (
    <Card sx={{ width: '25%', minWidth: isMobile ? '100%' : 400 }}>
      <CardContent>
        <Typography
          variant="h6"
          sx={{
            marginBottom: '20px',
            textAlign: 'left',
            color: '#828080',
          }}
          data-testid="signInHeader"
        >
          {t('SETTINGS.DANGER_ZONE')}
        </Typography>
        <Button
          variant="contained"
          size="medium"
          onClick={deleteOrder}
          data-testid="deleteOrder"
          sx={{ width: '200px', backgroundColor: 'red', color: 'white' }}
          disabled={!doesRoleExist(user, organization, ['ADMIN'])}
        >
          {t('ORDERS.DELETE')}
        </Button>
      </CardContent>
    </Card>
  );

  return (
    <Box
      display="flex"
      flexDirection="row"
      gap="20px"
      flexWrap="wrap"
      data-testid="orderDetailsPage"
    >
      <Box sx={{ width: '100%' }}>
        <Button
          variant="text"
          data-testid="ordersLink"
          onClick={() => history.push('../orders')}
        >
          <KeyboardBackspaceIcon /> {t('ORDER_DETAILS.GO_BACK')}
        </Button>
        {isLoading ? (
          <Skeleton animation="wave" sx={{ width: '20%' }} />
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 4,
            }}
          >
            <Typography
              variant="h5"
              mb={1.5}
              sx={{
                color: 'darkgray',
                paddingLeft: '24px',
                paddingTop: '12px',
              }}
            >
              {order?.code}
            </Typography>
            <Button
              variant="outlined"
              onClick={() => setIsDialogOpen(true)}
              startIcon={<PersonAddIcon />}
              sx={{ height: 40, alignSelf: 'center' }}
            >
              {t('ORDER_DETAILS.SHARE_ORDER')}
            </Button>
          </Box>
        )}
      </Box>

      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="customer-tabs"
          >
            <Tab label={t('ORDER_DETAILS.DETAILS')} {...a11yProps(0)} />
            <Tab label={t('ORDER_DETAILS.ATTACHMENTS')} {...a11yProps(1)} />
            <Tab label={t('ORDER_DETAILS.ORDER_ITEMS')} {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Box display="flex" flexDirection="column" gap={2}>
            {detailsCard}
            {dangerZoneCard}
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Attachments orderId={orderId} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <OrderItems orderId={orderId} />
        </TabPanel>
      </Box>
      <Snackbar
        open={isAlertOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          sx={{ width: '100%' }}
          data-testid="alertId"
        >
          {t(alertMessage)}
        </Alert>
      </Snackbar>
      <ShareOrderDialog
        isOpen={isDialogOpen}
        handleClose={() => setIsDialogOpen(false)}
        onSubmit={sendEmailShare}
        orderId={order?.id}
      />
    </Box>
  );
}
