import { FormHelperText, Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { parsePhoneNumber } from 'libphonenumber-js';
import { each, get, times } from 'lodash';
import { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../context/AuthContext';
import { useSystemSettings } from '../../../context/SystemSettingsContext';
import doesRoleExist from '../../../utils/doesRoleExist';
import {
  NUMBER_ONLY_REGEX,
  WORDS_ONLY_REGEX,
} from '../../../utils/regexHelpers';

export interface OrganizationDetailsFormInput {
  name: string;
  taxOfficeName: string;
  taxNumber: string;
  size: string;
  industry: string;
  web: string;
  phone: string;
  email: string;
  currency: string;
}

export default function BasicDetailsForm(props: {
  onSubmit: SubmitHandler<OrganizationDetailsFormInput>;
  isDetailsLoading: boolean;
}) {
  const { t } = useTranslation();
  const { organization, user } = useAuth();
  const { industries } = useSystemSettings();

  type formValue =
    | 'name'
    | 'taxOfficeName'
    | 'taxNumber'
    | 'industry'
    | 'size'
    | 'web'
    | 'phone'
    | 'email'
    | 'currency';

  const { control, handleSubmit, setValue } =
    useForm<OrganizationDetailsFormInput>();

  useEffect(() => {
    const fillTheValues = async () => {
      if (organization) {
        const fields = [
          'name',
          'taxOfficeName',
          'taxNumber',
          'size',
          'industry',
          'web',
          'phone',
          'email',
          'currency',
        ];

        each(fields, (field) => {
          const value = get(organization, field);
          if (value !== null && value !== undefined) {
            setValue(field as formValue, get(organization, field));
          }
        });
      }
    };

    fillTheValues();
  }, [organization, setValue]);

  const { onSubmit, isDetailsLoading } = props;

  if (isDetailsLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          padding: '10px',
        }}
      >
        {times(8, (index) => (
          <Skeleton animation="wave" sx={{ marginBottom: 6 }} key={index} />
        ))}
      </Box>
    );
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        padding: '10px',
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'contents' }}>
        <Controller
          name="name"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              onChange={onChange}
              error={!!error}
              disabled
              label={t('SETTINGS.ORGANIZATION_NAME')}
              helperText={error ? error.message : null}
              sx={{
                marginBottom: '20px',
              }}
              inputProps={{ 'data-testid': 'organizationNameInput' }}
            />
          )}
          rules={{
            required: `${t('SETTINGS.ORGANIZATION_NAME')} ${t(
              'SETTINGS.REQUIRED'
            )}`,
            maxLength: {
              value: 100,
              message: t('VALIDATION.MAX_LENGTH', { length: 100 }),
            },
          }}
        />
        <Controller
          name="size"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl
              fullWidth
              error={!!error}
              sx={{
                marginBottom: '20px',
              }}
            >
              <InputLabel id="demo-simple-select-label">
                {t('SETTINGS.ORGANIZATION_SIZE')}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value}
                error={!!error}
                label={t('SETTINGS.ORGANIZATION_SIZE')}
                onChange={onChange}
                inputProps={{ 'data-testid': 'organizationSizeInput' }}
                disabled={doesRoleExist(user, organization, ['OBSERVER'])}
              >
                <MenuItem value={'1-10'}>1 - 10</MenuItem>
                <MenuItem value={'10-50'}>10 - 50</MenuItem>
                <MenuItem value={'50-100'}>50 - 100</MenuItem>
                <MenuItem value={'100+'}>100+</MenuItem>
              </Select>
              <FormHelperText>
                {error
                  ? `${t('SETTINGS.ORGANIZATION_SIZE')} ${t(
                      'SETTINGS.REQUIRED'
                    )}`
                  : ''}
              </FormHelperText>
            </FormControl>
          )}
          rules={{
            required: `${t('SETTINGS.ORGANIZATION_SIZE')} ${t(
              'SETTINGS.REQUIRED'
            )}`,
          }}
        />
        <Controller
          name="industry"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl
              fullWidth
              error={!!error}
              sx={{
                marginBottom: '20px',
              }}
            >
              <InputLabel id="demo-simple-select-label">
                {t('SETTINGS.ORGANIZATION_INDUSTRY')}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value}
                error={!!error}
                label={t('SETTINGS.ORGANIZATION_INDUSTRY')}
                onChange={onChange}
                inputProps={{ 'data-testid': 'organizationSectorInput' }}
                disabled={doesRoleExist(user, organization, ['OBSERVER'])}
              >
                {(industries || []).map((industry: string) => (
                  <MenuItem value={industry} key={industry}>
                    {industry}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {error
                  ? `${t('SETTINGS.ORGANIZATION_INDUSTRY')} ${t(
                      'SETTINGS.REQUIRED'
                    )}`
                  : ''}
              </FormHelperText>
            </FormControl>
          )}
          rules={{
            required: `${t('SETTINGS.ORGANIZATION_INDUSTRY')} ${t(
              'SETTINGS.REQUIRED'
            )}`,
          }}
        />
        <Controller
          name="taxOfficeName"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              onChange={onChange}
              error={!!error}
              label={t('SETTINGS.ORGANIZATION_TAX_OFFICE_NAME')}
              helperText={error ? error.message : null}
              sx={{
                marginBottom: '20px',
              }}
              inputProps={{ 'data-testid': 'organizationTaxOfficeNameInput' }}
              disabled={doesRoleExist(user, organization, ['OBSERVER'])}
            />
          )}
          rules={{
            maxLength: {
              value: 50,
              message: t('VALIDATION.MAX_LENGTH', { length: 50 }),
            },

            pattern: {
              value: WORDS_ONLY_REGEX,
              message: `${t('VALIDATION.INVALID_VALUE')}`,
            },
          }}
        />
        <Controller
          name="taxNumber"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              onChange={onChange}
              error={!!error}
              label={t('SETTINGS.ORGANIZATION_TAX_NUMBER')}
              helperText={error ? error.message : null}
              sx={{
                marginBottom: '20px',
              }}
              inputProps={{ 'data-testid': 'organizationTaxNumberInput' }}
              disabled={doesRoleExist(user, organization, ['OBSERVER'])}
            />
          )}
          rules={{
            maxLength: {
              value: 50,
              message: t('VALIDATION.MAX_LENGTH', { length: 50 }),
            },

            pattern: {
              value: NUMBER_ONLY_REGEX,
              message: `${t('VALIDATION.ONLY_NUMBER')}`,
            },
          }}
        />
        <Controller
          name="web"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              onChange={onChange}
              error={!!error}
              label={t('SETTINGS.ORGANIZATION_WEB')}
              helperText={error ? error.message : null}
              sx={{
                marginBottom: '20px',
              }}
              inputProps={{ 'data-testid': 'organizationWebInput' }}
              disabled={doesRoleExist(user, organization, ['OBSERVER'])}
            />
          )}
          rules={{
            maxLength: {
              value: 100,
              message: t('VALIDATION.MAX_LENGTH', { length: 100 }),
            },
          }}
        />
        <Controller
          name="phone"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              onChange={onChange}
              error={!!error}
              label={t('SETTINGS.ORGANIZATION_PHONE')}
              helperText={error ? error.message : null}
              sx={{
                marginBottom: '20px',
              }}
              inputProps={{ 'data-testid': 'organizationPhoneInput' }}
              disabled={doesRoleExist(user, organization, ['OBSERVER'])}
            />
          )}
          rules={{
            required: `${t('CUSTOMERS.CUSTOMER_AUTHORIZED_PERSON_PHONE')} ${t(
              'CUSTOMERS.CUSTOMER.REQUIRED'
            )}`,
            maxLength: {
              value: 50,
              message: t('VALIDATION.MAX_LENGTH', { length: 50 }),
            },
            validate: {
              isValidPhone: (value) => {
                try {
                  return parsePhoneNumber(value, 'TR')?.isValid()
                    ? true
                    : t('VALIDATION.INVALID_PHONE_NUMBER');
                } catch (err) {
                  return t('VALIDATION.INVALID_PHONE_NUMBER');
                }
              },
            },
          }}
        />
        <Controller
          name="email"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              onChange={onChange}
              error={!!error}
              label={t('SETTINGS.ORGANIZATION_EMAIL')}
              helperText={error ? error.message : null}
              sx={{
                marginBottom: '20px',
              }}
              inputProps={{ 'data-testid': 'organizationEmailInput' }}
              disabled={doesRoleExist(user, organization, ['OBSERVER'])}
            />
          )}
          rules={{
            maxLength: {
              value: 50,
              message: t('VALIDATION.MAX_LENGTH', { length: 50 }),
            },
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: t('VALIDATION.INVALID_EMAIL'),
            },
          }}
        />
        <Controller
          name="currency"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl
              fullWidth
              error={!!error}
              sx={{
                marginBottom: '20px',
              }}
            >
              <InputLabel id="demo-simple-select-label">
                {t('CREATE_ORGANIZATION.CURRENCY')}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                data-testid="organizationSizeSelect"
                value={value}
                error={!!error}
                label={t('CREATE_ORGANIZATION.CURRENCY')}
                onChange={onChange}
                inputProps={{ 'data-testid': 'organizationSizeInput' }}
                disabled
              >
                <MenuItem value="TRY">TRY</MenuItem>
                <MenuItem value="USD">USD</MenuItem>
                <MenuItem value="EUR">EUR</MenuItem>
              </Select>
              <FormHelperText>
                {error
                  ? `${t('CREATE_ORGANIZATION.CURRENCY')} ${t(
                      'SIGNUP.REQUIRED'
                    )}`
                  : ''}
              </FormHelperText>
            </FormControl>
          )}
          rules={{
            required: `${t('CREATE_ORGANIZATION.CURRENCY')} ${t(
              'SIGNUP.REQUIRED'
            )}`,
          }}
        />
        <Button
          type="submit"
          variant="outlined"
          data-testid="updateOrganizationSubmit"
          sx={{
            marginTop: '20px',
            maxWidth: '80px',
          }}
          disabled={!doesRoleExist(user, organization, ['ADMIN'])}
        >
          {t('SETTINGS.UPDATE')}
        </Button>
      </form>
    </Box>
  );
}
