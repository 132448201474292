import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Title from './Title';

import { camelCase, mapKeys, orderBy, toUpper } from 'lodash';
import { useHistory } from 'react-router-dom';
import axios from '../../../../axios';
import { useAuth } from '../../../../context/AuthContext';
import { Customer } from '../../../../models/customer.model';

export interface Data {
  id: string;
  shortName: string;
  fullName: string;
  taxOffice: string;
  taxNumber: string;
  type: string;
  industry: string;
  authorizedPersonName: string;
  authorizedPersonEmail: string;
  authorizedPersonPhone: string;
  createdAt: string;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'shortName',
    numeric: false,
    disablePadding: true,
    label: 'CUSTOMERS.CUSTOMER_SHORT_NAME',
  },
  {
    id: 'industry',
    numeric: false,
    disablePadding: false,
    label: 'CUSTOMERS.CUSTOMER_INDUSTRY',
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'CUSTOMERS.CUSTOMER_TYPE',
  },
];

export default function RecentCustomers() {
  const { t } = useTranslation();
  const [rows, setRows] = React.useState<Data[]>([]);
  const { organization } = useAuth();

  const fetchCustomers = useCallback(async () => {
    const customersResp = await axios.get(
      `api/organizations/${organization?.id}/customers`
    );
    const customersData = customersResp.data.data.items.map((item: any) => {
      return mapKeys(item, (v, k) => camelCase(k));
    });
    const customerData = customersData.map((customer: Customer) => ({
      id: customer.id.toString(),
      shortName: customer.shortName,
      fullName: customer.fullName,
      taxOffice: customer.taxOffice,
      taxNumber: customer.taxNumber,
      type: customer.type,
      industry: customer.industry,
      authorizedPersonName: customer.authorizedPersonName,
      authorizedPersonEmail: customer.authorizedPersonEmail,
      authorizedPersonPhone: customer.authorizedPersonPhone,
    }));
    setRows(orderBy(customerData, ['id'], ['desc']).slice(0, 10));
  }, [organization?.id]);

  useEffect(() => {
    fetchCustomers();
  }, [fetchCustomers]);

  const history = useHistory();

  return (
    <React.Fragment>
      <Title>{t('RECENT_CUSTOMERS')}</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell key={headCell.label}>{t(headCell.label)}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.shortName}
              hover
              onClick={() => {
                history.push(`./customer/${row.id}`);
              }}
              sx={{ cursor: 'pointer' }}
            >
              <TableCell>{row.shortName}</TableCell>
              <TableCell>{row.industry}</TableCell>
              <TableCell>
                {t(`SYSTEM_CUSTOMER_TYPES_${toUpper(row.type)}`)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
