import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { sortBy } from 'lodash';
import { useState } from 'react';
import { Category } from '../../../models/category.model';
import doesRoleExist from '../../../utils/doesRoleExist';
import { useAuth } from '../../../context/AuthContext';

export default function CategoriesDialog({
  isOpen,
  handleClose,
  addNewCategory,
  categories,
  deleteCategory,
}: {
  isOpen: boolean;
  handleClose: () => void;
  addNewCategory: (name: string) => void;
  categories: Category[];
  deleteCategory: (categoryId: string) => void;
}) {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const { organization, user } = useAuth();

  return (
    <Box>
      <Dialog open={isOpen} maxWidth="sm" fullWidth onClose={handleClose}>
        <DialogTitle>
          {t('CATEGORIES.NAME')}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider />
        <Box sx={{ minWidth: '300px' }}>
          <Box sx={{ maxHeight: '150px', overflow: 'auto', mb: 2 }}>
            <List dense>
              {sortBy(categories, ['name']).map((category: Category) => (
                <ListItem
                  key={category.id}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      data-testid="deleteCategoryButton"
                      aria-label="delete"
                      onClick={() => deleteCategory(category.id)}
                      disabled={
                        !doesRoleExist(user, organization, [
                          'ADMIN',
                          'TEAM_MANAGER',
                          'TEAM_MEMBER',
                        ])
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemText primary={category.name} />
                </ListItem>
              ))}
            </List>
          </Box>
          <Divider />
          <Box
            sx={{
              margin: '16px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <TextField
              sx={{ flex: 1, mr: 2 }}
              size="small"
              id="outlined-basic"
              inputProps={{ 'data-testid': 'categoryNameInput' }}
              label={t('CATEGORY.NAME')}
              variant="outlined"
              value={name}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setName(event.target.value);
              }}
              disabled={doesRoleExist(user, organization, ['OBSERVER'])}
            />
            <Button
              variant="outlined"
              size="small"
              data-testid="createButton"
              sx={{ width: 'fit-content', margin: 'auto' }}
              onClick={() => {
                if (name) {
                  addNewCategory(name);
                  setName('');
                }
              }}
              disabled={
                !doesRoleExist(user, organization, [
                  'ADMIN',
                  'TEAM_MANAGER',
                  'TEAM_MEMBER',
                ])
              }
            >
              {t('CATEGORY.ADD_NEW_ADD')}
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
}
