import BarChartIcon from '@mui/icons-material/BarChart';
import BusinessIcon from '@mui/icons-material/Business';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupsIcon from '@mui/icons-material/Groups';
import InventoryIcon from '@mui/icons-material/Inventory';
import MapIcon from '@mui/icons-material/Map';
import MenuIcon from '@mui/icons-material/Menu';
import PeopleIcon from '@mui/icons-material/People';
import SellIcon from '@mui/icons-material/Sell';
import Settings from '@mui/icons-material/Settings';
import TaskIcon from '@mui/icons-material/Task';
import {
  Chip,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
} from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import { default as Drawer, default as MuiDrawer } from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { CSSObject, Theme, styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { camelCase, get, mapKeys } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import axios from '../../axios';
import LoadingPage from '../../components/LoadingPage';
import { useAuth } from '../../context/AuthContext';
import { useSystemSettings } from '../../context/SystemSettingsContext';
import { Organization } from '../../models/organization.model';
import doesRoleExist from '../../utils/doesRoleExist';
import AccountMenu from './AccountMenu';
import CreateOrganizationPage from './CreateOrganizationPage/CreateOrganizationPage';
import CustomerDetailsPage from './CustomersPage/CustomerDetailsPage';
import CustomersPage from './CustomersPage/CustomersPage';
import MapPage from './MapPage/MapPage';
import NotFoundPage from './NotFoundPage/NotFoundPage';
import OrderDetailsPage from './OrdersPage/OrderDetailsPage';
import OrdersPage from './OrdersPage/OrdersPage';
import OverviewPage from './Overview/OverviewPage';
import ProductDetailsPage from './ProductsPage/ProductDetailsPage';
import ProductsPage from './ProductsPage/ProductsPage';
import ReportsPage from './ReportsPage/ReportsPage';
import SettingsPage from './SettingsPage/SettingsPage';
import SubscriptionChangeDialog from './SettingsPage/SubscriptionChangeDialog';
import TaskDetailsPage from './TasksPage/TaskDetailsPage';
import TasksPage from './TasksPage/TasksPage';
import TeamsPage from './TeamsPage/TeamsPage';
import UsersPage from './UsersPage/UsersPage';
import LocationTracker from '../../components/LocationTracker';

const drawerWidth = 240;

const pageNameMap = {
  '/app/overview': 'PAGE_NAME.OVERVIEW',
  '/app/users': 'PAGE_NAME.USERS',
  '/app/teams': 'PAGE_NAME.TEAMS',
  '/app/reports': 'PAGE_NAME.REPORTS',
  '/app/settings': 'PAGE_NAME.SETTINGS',
  '/app/customers': 'PAGE_NAME.CUSTOMERS',
  '/app/tasks': 'PAGE_NAME.TASKS',
  '/app/orders': 'PAGE_NAME.ORDERS',
  '/app/products': 'PAGE_NAME.PRODUCTS',
};

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DesktopDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export default function ProfilePage() {
  const theme = useTheme();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubscriptionChangeOpen, setIsSubscriptionChangeOpen] =
    useState(false);
  const [isSubscriptionLoading, setIsSubscriptionLoading] = useState(true);
  const { path } = useRouteMatch();
  const {
    setIndustries,
    setRoles,
    setCustomerTypes,
    setCustomerActivityTypes,
    setCustomerActivityChannels,
    setTaskPriorityTypes,
    setTaskStatusTypes,
    setOrderStatusTypes,
  } = useSystemSettings();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const {
    setUser,
    user,
    organization,
    setOrganizations,
    setOrganization,
    subscription,
    setSubscription,
  } = useAuth();
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  let defaultLanguage =
    i18n.language || window.localStorage.i18nextLng || 'en-US';
  if (!['tr-TR', 'en-US'].includes(defaultLanguage)) {
    defaultLanguage = 'en-US';
  }

  const [language, setLanguage] = React.useState(defaultLanguage);

  const handleLanguageChange = (event: SelectChangeEvent) => {
    setLanguage(event.target.value);
    i18n.changeLanguage(event.target.value);
    localStorage.setItem('lang', event.target.value);
  };

  const fetchSubscription = useCallback(
    async (id: string) => {
      setIsSubscriptionLoading(true);
      if (process.env.REACT_APP_SUBSCRIPTION) {
        setSubscription({
          status: 'active',
          product: process.env.REACT_APP_SUBSCRIPTION,
        });
      } else {
        const subscriptionsResp = await axios.get(
          `api/organizations/${id}/subscriptions`
        );
        setSubscription(
          mapKeys(subscriptionsResp.data, (_v, k) => camelCase(k))
        );
      }
      setIsSubscriptionLoading(false);
    },
    [setSubscription]
  );

  useEffect(() => {
    const fetchOrganizations = async () => {
      const organizationsResp = await axios.get('api/organizations');

      const organizations = organizationsResp.data.data.items.map(
        (item: Organization) => {
          const org = new Organization();
          const orgObj = mapKeys(item, (_v, k) => camelCase(k));
          return org.deserialize(orgObj);
        }
      );

      setOrganizations(organizations);

      const activeOrgs = organizations.filter(
        (i: Organization) => !i.pivot.deleted_at
      );

      if (activeOrgs.length > 0) {
        const selectedOrganizationId = localStorage.getItem(
          'selectedOrganization'
        );

        let selectedOrganization: Organization = organizations.find(
          (org: Organization) =>
            org.id.toString() === String(selectedOrganizationId)
        );

        if (!selectedOrganization || selectedOrganization.pivot.deleted_at) {
          selectedOrganization = activeOrgs[0];
        }

        setOrganization(selectedOrganization);
      }
      setIsLoading(false);
    };
    fetchOrganizations();
  }, [setOrganizations, setOrganization, history]);

  useEffect(() => {
    if (organization) {
      fetchSubscription(organization.id);
      localStorage.setItem('selectedOrganization', organization.id);
    }
  }, [organization, fetchSubscription]);

  useEffect(() => {
    const fetchSystemVariables = async () => {
      const industriesResp = await axios.get('api/system-configs/industries');
      setIndustries(industriesResp.data.data);
      const customerTypesResp = await axios.get(
        'api/system-configs/customer_types'
      );
      setCustomerTypes(Object.keys(customerTypesResp.data.data));

      const rolesResp = await axios.get('api/system-configs/roles');
      setRoles(rolesResp.data.data);

      const taskPriorityResp = await axios.get(
        'api/system-configs/task_priority'
      );
      setTaskPriorityTypes(Object.keys(taskPriorityResp.data.data));

      const taskStatusResp = await axios.get('api/system-configs/task_status');
      setTaskStatusTypes(Object.keys(taskStatusResp.data.data));

      const orderStatusResp = await axios.get(
        'api/system-configs/order_status'
      );
      setOrderStatusTypes(Object.keys(orderStatusResp.data.data));

      const customerActivityResp = await axios.get(
        'api/system-configs/customer_activity_types'
      );
      setCustomerActivityTypes(Object.keys(customerActivityResp.data.data));
      const customerActivityChannelResp = await axios.get(
        'api/system-configs/customer_activity_channels'
      );
      setCustomerActivityChannels(
        Object.keys(customerActivityChannelResp.data.data)
      );
    };

    fetchSystemVariables();
  }, [
    setIndustries,
    setCustomerTypes,
    setRoles,
    setCustomerActivityTypes,
    setCustomerActivityChannels,
    setTaskPriorityTypes,
    setTaskStatusTypes,
    setOrderStatusTypes,
  ]);

  const handleMobileDrawerToggle = () => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  };

  const handleDrawerOpen = () => {
    if (isMobile) {
      handleMobileDrawerToggle();
    } else {
      setOpen(true);
    }
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logout = async () => {
    setIsLoading(true);
    await axios.post('api/auth/logout');
    setUser(null);
    localStorage.removeItem('user');
    history.push('/');
  };

  const DrawerList = (
    <List>
      {doesRoleExist(user, organization, ['ADMIN', 'OBSERVER']) && [
        <ListItem
          key={t('USERS.Overview')}
          disablePadding
          sx={{ display: 'block' }}
          data-testid="overviewLink"
          onClick={() => {
            history.push(`${path}/overview`);
          }}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open || mobileDrawerOpen ? 'initial' : 'center',
              px: 2.5,
            }}
          >
            <Tooltip title={t('USERS.OVERVIEW')} placement="right">
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open || mobileDrawerOpen ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <DashboardIcon />
              </ListItemIcon>
            </Tooltip>

            <ListItemText
              primary={t('USERS.OVERVIEW')}
              sx={{ opacity: open || mobileDrawerOpen ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>,
        <ListItem
          key={t('USERS.LINK')}
          disablePadding
          sx={{ display: 'block' }}
          data-testid="usersLink"
          onClick={() => {
            history.push(`${path}/users`);
          }}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open || mobileDrawerOpen ? 'initial' : 'center',
              px: 2.5,
            }}
          >
            <Tooltip title={t('USERS.LINK')} placement="right">
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open || mobileDrawerOpen ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <PeopleIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText
              primary={t('USERS.LINK')}
              sx={{ opacity: open || mobileDrawerOpen ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>,
        <ListItem
          key={t('ORGANIZATION.TEAMS.LINK')}
          disablePadding
          sx={{ display: 'block' }}
          data-testid="teamsLink"
          onClick={() => {
            history.push(`${path}/teams`);
          }}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open || mobileDrawerOpen ? 'initial' : 'center',
              px: 2.5,
            }}
          >
            <Tooltip title={t('ORGANIZATION.TEAMS.LINK')} placement="right">
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open || mobileDrawerOpen ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <GroupsIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText
              primary={t('ORGANIZATION.TEAMS.LINK')}
              sx={{ opacity: open || mobileDrawerOpen ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>,
        <ListItem
          key={t('PRODUCTS.LINK')}
          disablePadding
          sx={{ display: 'block' }}
          data-testid="productsLink"
          onClick={() => {
            history.push(`${path}/products`);
          }}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open || mobileDrawerOpen ? 'initial' : 'center',
              px: 2.5,
            }}
          >
            <Tooltip title={t('PRODUCTS.LINK')} placement="right">
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open || mobileDrawerOpen ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <InventoryIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText
              primary={t('PRODUCTS.LINK')}
              sx={{ opacity: open || mobileDrawerOpen ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>,
      ]}
      <ListItem
        key={t('ORDERS.LINK')}
        disablePadding
        sx={{ display: 'block' }}
        data-testid="tasksLink"
        onClick={() => {
          history.push(`${path}/orders`);
        }}
      >
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open || mobileDrawerOpen ? 'initial' : 'center',
            px: 2.5,
          }}
        >
          <Tooltip title={t('ORDERS.LINK')} placement="right">
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open || mobileDrawerOpen ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              <SellIcon />
            </ListItemIcon>
          </Tooltip>
          <ListItemText
            primary={t('ORDERS.LINK')}
            sx={{ opacity: open || mobileDrawerOpen ? 1 : 0 }}
          />
        </ListItemButton>
      </ListItem>
      <ListItem
        key={t('CUSTOMERS.LINK')}
        disablePadding
        sx={{ display: 'block' }}
        data-testid="customersLink"
        onClick={() => {
          history.push(`${path}/customers`);
        }}
      >
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open || mobileDrawerOpen ? 'initial' : 'center',
            px: 2.5,
          }}
        >
          <Tooltip title={t('CUSTOMERS.LINK')} placement="right">
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open || mobileDrawerOpen ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              <BusinessIcon />
            </ListItemIcon>
          </Tooltip>
          <ListItemText
            primary={t('CUSTOMERS.LINK')}
            sx={{ opacity: open || mobileDrawerOpen ? 1 : 0 }}
          />
        </ListItemButton>
      </ListItem>
      <ListItem
        key={t('TASKS.LINK')}
        disablePadding
        sx={{ display: 'block' }}
        data-testid="tasksLink"
        onClick={() => {
          history.push(`${path}/tasks`);
        }}
      >
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open || mobileDrawerOpen ? 'initial' : 'center',
            px: 2.5,
          }}
        >
          <Tooltip title={t('TASKS.LINK')} placement="right">
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open || mobileDrawerOpen ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              <TaskIcon />
            </ListItemIcon>
          </Tooltip>
          <ListItemText
            primary={t('TASKS.LINK')}
            sx={{ opacity: open || mobileDrawerOpen ? 1 : 0 }}
          />
        </ListItemButton>
      </ListItem>

      {doesRoleExist(user, organization, [
        'ADMIN',
        'OBSERVER',
        'TEAM_MANAGER',
      ]) && [
        <ListItem
          key={t('MAP_PAGE.TITLE')}
          disablePadding
          sx={{ display: 'block' }}
          data-testid="mapLink"
          onClick={() => {
            if (subscription?.product === 'ecosh-professional') {
              history.push(`${path}/map`);
            } else {
              setIsSubscriptionChangeOpen(true);
            }
          }}
        >
          <ListItemButton
            disabled={subscription?.product !== 'ecosh-professional'}
            sx={{
              minHeight: 48,
              justifyContent: open || mobileDrawerOpen ? 'initial' : 'center',
              px: 2.5,
            }}
          >
            <Tooltip title={t('MAP_PAGE.TITLE')} placement="right">
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open || mobileDrawerOpen ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <MapIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText
              primary={
                <>
                  <span>{t('MAP_PAGE.TITLE')}</span>{' '}
                  <Chip label="Professional" size="small" />
                </>
              }
              sx={{ opacity: open || mobileDrawerOpen ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>,
        <ListItem
          key={t('REPORTS.TITLE')}
          disablePadding
          sx={{ display: 'block' }}
          data-testid="reportsLink"
          onClick={() => {
            if (subscription?.product !== 'ecosh-lite') {
              history.push(`${path}/reports`);
            } else {
              setIsSubscriptionChangeOpen(true);
            }
          }}
        >
          <ListItemButton
            disabled={subscription?.product === 'ecosh-lite'}
            sx={{
              minHeight: 48,
              justifyContent: open || mobileDrawerOpen ? 'initial' : 'center',
              px: 2.5,
            }}
          >
            <Tooltip title={t('REPORTS.TITLE')} placement="right">
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open || mobileDrawerOpen ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <BarChartIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText
              primary={
                <>
                  <span>{t('REPORTS.TITLE')}</span>{' '}
                  <Chip size="small" label="Standard" />
                </>
              }
              sx={{ opacity: open || mobileDrawerOpen ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>,
      ]}

      {doesRoleExist(user, organization, ['ADMIN', 'OBSERVER']) && (
        <ListItem
          key={t('SETTINGS.TITLE')}
          disablePadding
          sx={{ display: 'block' }}
          data-testid="settingsLink"
          onClick={() => {
            history.push(`${path}/settings`);
          }}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open || mobileDrawerOpen ? 'initial' : 'center',
              px: 2.5,
            }}
          >
            <Tooltip title={t('SETTINGS.TITLE')} placement="right">
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open || mobileDrawerOpen ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <Settings />
              </ListItemIcon>
            </Tooltip>
            <ListItemText
              primary={t('SETTINGS.TITLE')}
              sx={{ opacity: open || mobileDrawerOpen ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>
      )}
    </List>
  );

  const MobileDrawerContent = organization && (
    <Box>
      <Toolbar />
      <Divider />
      {DrawerList}
    </Box>
  );

  const DrawerContent = organization && (
    <Box sx={{ width: 'auto' }}>
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose} data-testid="drawerClose">
          {theme.direction === 'rtl' ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />
      {DrawerList}
    </Box>
  );

  const getPageName = useCallback(() => {
    return t(get(pageNameMap, location.pathname));
  }, [t, location.pathname]);

  const getRedirectPathName = useCallback(() => {
    if (doesRoleExist(user, organization, ['ADMIN', 'OBSERVER'])) {
      return '/app/overview';
    }

    return '/app/orders';
  }, [user, organization]);

  const container =
    window !== undefined ? () => window.document.body : undefined;

  /* istanbul ignore next */
  const RedirectOrganizationRoute = useCallback(
    ({ children, ...rest }: any) => {
      return (
        <Route
          {...rest}
          render={({ location }) =>
            organization ? (
              children
            ) : (
              <Redirect
                to={{
                  pathname: '/app/createOrganization',
                  state: { from: location },
                }}
              />
            )
          }
        />
      );
    },
    [organization]
  );

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <Box sx={{ display: 'flex', overflowX: 'auto' }} data-testid="profilePage">
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          {organization && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              data-testid="drawerOpen"
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
          )}

          <Typography
            variant="h6"
            color="inherit"
            component="div"
            data-testid="appLink"
            onClick={() => {
              history.push('/app');
            }}
            sx={{ cursor: 'pointer' }}
          >
            ECOSH
          </Typography>

          <Typography
            variant="subtitle1"
            color="inherit"
            component="div"
            sx={{ ml: 1, alignSelf: 'center' }}
          >
            {subscription?.product === 'ecosh-professional' && 'Professional'}
            {subscription?.product === 'ecosh-standard' && 'Standard'}
            {subscription?.product === 'ecosh-lite' && 'Lite'}
          </Typography>

          {subscription?.status === 'trial' && (
            <Chip
              sx={{ color: 'white', ml: 2 }}
              label={t('SUBSCRIPTION_FREE_TRIAL')}
            />
          )}

          <Box display="flex" mr={1} justifyContent="flex-end" width="100%">
            <FormControl
              variant="standard"
              sx={{ m: 1, width: 70, color: 'white' }}
            >
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={language}
                onChange={handleLanguageChange}
                sx={{
                  color: 'white',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'white',
                  },
                  '& .MuiSvgIcon-root': {
                    color: 'white',
                  },
                  ':before': { borderBottomColor: 'white' },
                  ':after': { borderBottomColor: 'white' },
                }}
              >
                <MenuItem value="en-US">EN</MenuItem>
                <MenuItem value="tr-TR">TR</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box
            sx={{
              ml: 'auto',
              mr: 0.5,
              width: 100,
              overflow: 'elipsis',
              textAlign: 'right',
            }}
            display="flex"
            flexDirection="column"
          >
            <Typography
              sx={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                width: 100,
              }}
              variant="caption"
            >
              {organization?.name}
            </Typography>
            <Typography
              sx={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                width: 100,
              }}
              variant="caption"
            >
              {user?.email}
            </Typography>
          </Box>
          <AccountMenu logOutHandler={logout} />
        </Toolbar>
      </AppBar>
      <Drawer
        container={container}
        variant="temporary"
        open={mobileDrawerOpen}
        onClose={handleMobileDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {MobileDrawerContent}
      </Drawer>
      <DesktopDrawer
        variant="permanent"
        open={open}
        sx={{
          display: { xs: 'none', sm: 'block' },
        }}
      >
        {DrawerContent}
      </DesktopDrawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: '100%',
          marginTop: '64px',
        }}
      >
        <Typography
          variant="h5"
          mb={1.5}
          sx={{ color: 'darkgray' }}
          data-testid="pageName"
        >
          {getPageName()}
        </Typography>

        <Switch>
          <Route exact path="/app">
            <Redirect
              to={{
                pathname: getRedirectPathName(),
              }}
            />
          </Route>
          <RedirectOrganizationRoute path={`${path}/customers`}>
            <CustomersPage />
          </RedirectOrganizationRoute>
          <RedirectOrganizationRoute path={`${path}/orders`}>
            <OrdersPage />
          </RedirectOrganizationRoute>
          <RedirectOrganizationRoute path={`${path}/order/:orderId`}>
            <OrderDetailsPage />
          </RedirectOrganizationRoute>
          <RedirectOrganizationRoute path={`${path}/customer/:customerId`}>
            <CustomerDetailsPage />
          </RedirectOrganizationRoute>
          <RedirectOrganizationRoute path={`${path}/tasks`}>
            <TasksPage />
          </RedirectOrganizationRoute>
          <RedirectOrganizationRoute path={`${path}/task/:taskId`}>
            <TaskDetailsPage />
          </RedirectOrganizationRoute>
          {doesRoleExist(user, organization, [
            'ADMIN',
            'OBSERVER',
            'TEAM_MANAGER',
          ]) && [
            subscription?.product === 'ecosh-professional' && (
              <RedirectOrganizationRoute
                key={`${path}/map`}
                path={`${path}/map`}
              >
                <MapPage />
              </RedirectOrganizationRoute>
            ),
            subscription?.product !== 'ecosh-lite' && (
              <RedirectOrganizationRoute
                key={`${path}/reports`}
                path={`${path}/reports`}
              >
                <ReportsPage />
              </RedirectOrganizationRoute>
            ),
          ]}

          {doesRoleExist(user, organization, ['ADMIN', 'OBSERVER']) && [
            <RedirectOrganizationRoute
              path={`${path}/products`}
              key={`${path}/products`}
            >
              <ProductsPage />
            </RedirectOrganizationRoute>,
            <RedirectOrganizationRoute
              path={`${path}/product/:productId`}
              key={`${path}/product/:productId`}
            >
              <ProductDetailsPage />
            </RedirectOrganizationRoute>,
            <RedirectOrganizationRoute
              path={`${path}/overview`}
              key={`${path}/overview`}
            >
              <OverviewPage />
            </RedirectOrganizationRoute>,
            <RedirectOrganizationRoute
              path={`${path}/teams`}
              key={`${path}/teams`}
            >
              <TeamsPage />
            </RedirectOrganizationRoute>,
            <RedirectOrganizationRoute
              path={`${path}/users`}
              key={`${path}/users`}
            >
              <UsersPage />
            </RedirectOrganizationRoute>,
            <RedirectOrganizationRoute
              path={`${path}/settings`}
              key={`${path}/settings`}
            >
              <SettingsPage />
            </RedirectOrganizationRoute>,
          ]}
          <Route
            path={`${path}/createOrganization`}
            key={`${path}/createOrganization`}
          >
            <CreateOrganizationPage />
          </Route>
          <Route path="/">
            <NotFoundPage />
          </Route>
        </Switch>
      </Box>
      <SubscriptionChangeDialog
        open={
          isSubscriptionChangeOpen ||
          (!['active', 'trial', 'canceled'].includes(subscription?.status) &&
            !isLoading &&
            !isSubscriptionLoading)
        }
        handleClose={() => setIsSubscriptionChangeOpen(false)}
      />
      <LocationTracker />
    </Box>
  );
}
