import axios from '../../../axios';
import React from 'react';
import { useAuth } from '../../../context/AuthContext';

import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TextField, FormControl } from '@mui/material';

const SalesPage = () => {
  interface Filters {
    startDate: string | null;
    endDate: string | null;
  }

  const [filters, setFilters] = React.useState<Filters>({
    startDate: null,
    endDate: null,
  });

  const [activities, setActivities] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(null);
  const [pagination, setPagination] = React.useState({
    limit: 10,
    page: 0,
  });

  const { organization } = useAuth();
  const { t } = useTranslation();

  const fetchActivities = React.useCallback(async () => {
    const params = {
      filters: {
        status: 'done',
      },
      pagination: {
        enabled: 1,
        limit: pagination?.limit,
        page: pagination?.page + 1,
      },
    } as any;

    if (filters?.startDate) {
      params.filters = params.filters || {};
      params.filters.order_date__$gte = filters.startDate;
    }

    if (filters?.endDate) {
      params.filters = params.filters || {};
      params.filters.order_date__$lte = filters.endDate;
    }

    const { data: { data: { items = [], total_count = 0 } = {} } = {} } =
      await axios.get(`api/organizations/${organization?.id}/orders`, {
        params,
      });

    setActivities(items);

    if (totalCount !== total_count) {
      setTotalCount(total_count);
    }
  }, [organization, pagination, totalCount, filters]);

  const columns: GridColDef<(typeof activities)[number]>[] = [
    { field: 'code', headerName: 'Code', width: 90 },
    {
      field: 'customer_full_name',
      headerName: t(`CUSTOMERS.CUSTOMER_FULL_NAME`),
      width: 150,
      editable: false,
      valueGetter: (_val, row: any) => {
        if (!row.customer) return '';
        return `${row?.customer?.full_name}`;
      },
    },
    {
      field: 'authorized_person_name',
      headerName: t(`CUSTOMERS.CUSTOMER_AUTHORIZED_PERSON_NAME`),
      width: 150,
      editable: false,
      valueGetter: (_val, row: any) => {
        if (!row.customer) return '';
        return `${row?.customer?.authorized_person_name}`;
      },
    },
    {
      field: 'customer_tax_office',
      headerName: t(`CUSTOMERS.CUSTOMER_TAX_OFFICE`),
      width: 150,
      editable: false,
      valueGetter: (_val, row: any) => {
        if (!row.customer) return '';
        return `${row?.customer?.tax_office}`;
      },
    },
    {
      field: 'customer_tax_number',
      headerName: t(`CUSTOMERS.CUSTOMER_TAX_NUMBER`),
      width: 150,
      editable: false,
      valueGetter: (_val, row: any) => {
        if (!row.customer) return '';
        return `${row?.customer?.tax_number}`;
      },
    },
    {
      field: 'owner',
      headerName: t(`ORDER.OWNER`),
      width: 150,
      editable: false,
      valueGetter: (_val, row: any) => {
        if (!row.owner) return '';
        return `${row?.owner?.first_name} ${row?.owner?.last_name}`;
      },
    },
    {
      field: 'note',
      headerName: t(`ORDERS.NOTE`),
      width: 150,
      editable: false,
    },

    {
      field: 'total_amount',
      headerName: t(`ORDER_ITEMS.TOTAL_AMOUNT`),
      width: 150,
      editable: false,
    },
    {
      field: 'tax_amount',
      headerName: t(`ORDER_ITEMS.TAX_AMOUNT`),
      width: 150,
      editable: false,
    },
    {
      field: 'total_discount',
      headerName: t(`ORDERS.DISCOUNT`),
      width: 150,
      editable: false,
    },
    {
      field: 'order_date',
      headerName: t(`ORDERS.ORDER_DATE`),
      width: 150,
      editable: false,
    },
    {
      field: 'number_of_items',
      headerName: t(`ORDERS.NUMBER_OF_ITEMS`),
      width: 150,
      editable: false,
      valueGetter: (_val, row: any) => {
        return row?.items?.length || 0;
      },
    },
  ];

  React.useEffect(() => {
    fetchActivities();
  }, [fetchActivities]);

  return (
    <Box>
      <FormControl sx={{ width: 200, ml: 2 }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={filters?.startDate}
            onChange={(date) => setFilters({ ...filters, startDate: date })}
            label={t('FILTERS.START_DATE')}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </FormControl>

      <FormControl sx={{ width: 200, ml: 2 }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={filters?.endDate}
            onChange={(date) => setFilters({ ...filters, endDate: date })}
            label={t('FILTERS.END_DATE')}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </FormControl>
      <DataGrid
        rows={activities}
        columns={columns}
        paginationModel={{
          pageSize: pagination?.limit,
          page: pagination?.page,
        }}
        onPaginationModelChange={(params) => {
          if (params.pageSize !== pagination.limit) {
            params.page = 0;
          }

          setPagination({
            limit: params.pageSize,
            page: params.page,
          });
        }}
        pageSizeOptions={[10]}
        disableRowSelectionOnClick
        rowCount={totalCount || 0}
        paginationMode="server"
        sx={{ minHeight: 500 }}
      />
    </Box>
  );
};

export default SalesPage;
