import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import { camelCase, mapKeys } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from '../../../axios';
import { useAuth } from '../../../context/AuthContext';
import { useNotification } from '../../../context/NotificationContext';
import { Team } from '../../../models/team.model';
import { User } from '../../../models/user.model';
import doesRoleExist from '../../../utils/doesRoleExist';
import AddTeamDialog, { CreateTeamInput } from './AddTeamDialog';
import TeamsTable, { Data } from './TeamsTable';
import UpdateTeamDialog, { UpdateData } from './UpdateTeamDialog';

export default function TeamsPage() {
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { organization, user } = useAuth();
  const [users, setUsers] = useState<User[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<Data>({
    id: '',
    name: '',
    managerId: '',
    managerName: '',
    users: [],
  });
  const [rows, setRows] = React.useState<Data[]>([]);
  const confirm = useConfirm();
  const { showMessage } = useNotification();

  const handleClose = () => {
    setIsUpdateDialogOpen(false);
    setIsDialogOpen(false);
  };

  const fetchTeamUsers = useCallback(
    async (row: Data) => {
      /* istanbul ignore next */
      if (row.users.length > 0) {
        return;
      }
      const teamUsersResp = await axios.get(
        `api/organizations/${organization?.id}/teams/${row.id}`
      );

      const usersData = teamUsersResp.data.data.users.map((item: any) => {
        return mapKeys(item, (v, k) => camelCase(k));
      });

      const users: User[] = usersData.map((userObj: User) => {
        const user = new User();
        return user.deserialize(userObj);
      });
      row.users = users;
    },
    [organization?.id]
  );

  const fetchTeams = useCallback(async () => {
    setIsLoading(true);
    const teamsResp = await axios.get(
      `api/organizations/${organization?.id}/teams`
    );
    const teamsData = teamsResp.data.data.items.map((item: any) => {
      item.manager = mapKeys(item.manager, (v, k) => camelCase(k));
      return mapKeys(item, (v, k) => camelCase(k));
    });
    setRows(
      teamsData.map((team: Team) => ({
        id: team.id.toString(),
        name: team.name,
        managerId: team.manager.id.toString(),
        managerName: `${team.manager.firstName} ${team.manager.lastName}`,
        users: [],
      }))
    );

    const usersResp = await axios.get(
      `api/organizations/${organization?.id}/users`
    );
    const usersData = usersResp.data.data.items.map((item: any) => {
      return mapKeys(item, (v, k) => camelCase(k));
    });

    setUsers(
      usersData.map((userObj: User) => {
        const user = new User();
        return user.deserialize(userObj);
      })
    );

    setIsLoading(false);
  }, [organization?.id]);

  useEffect(() => {
    fetchTeams();
  }, [fetchTeams]);

  const addNewTeam = useCallback(
    async (data: CreateTeamInput) => {
      handleClose();
      await axios.post(`api/organizations/${organization?.id}/teams`, {
        name: data.name,
        manager_id: data.managerId,
        users: data.users,
      });
      fetchTeams();
    },
    [fetchTeams, organization?.id]
  );

  const updateTeam = useCallback(
    async (data: UpdateData) => {
      handleClose();
      await axios.put(
        `api/organizations/${organization?.id}/teams/${data.id}`,
        {
          name: data.name,
          manager_id: data.managerId,
          users: data.users,
        }
      );

      fetchTeams();
      setSelectedTeam({} as Data);
    },
    [fetchTeams, organization?.id]
  );

  const deleteTeam = (documentId: string) => {
    confirm({
      title: t('CONFIRMATION.ARE_YOU_SURE'),
      description: t('CONFIRMATION.DESCRIPTION'),
      confirmationText: t('CONFIRMATION.YES'),
      cancellationText: t('CONFIRMATION.CANCEL'),
    })
      .then(async () => {
        await axios.delete(
          `api/organizations/${organization?.id}/teams/${documentId}`
        );

        setSelectedTeam({} as Data);
        showMessage({
          message: t('ORGANIZATION.TEAM_DELETED_SUCCESSFULLY'),
        });

        await fetchTeams();
      })
      .catch(() => {});
  };

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column' }}
      data-testid="teamsPage"
    >
      <Box
        sx={{
          flexGrow: 1,
          p: 2,
          display: 'flex',
          justifyContent: 'flex-end',
          paddingRight: '0px',
        }}
      >
        {doesRoleExist(user, organization, ['ADMIN']) && (
          <Button
            variant="outlined"
            onClick={() => {
              setIsDialogOpen(true);
            }}
            data-testid="createNewTeamButton"
          >
            {t('ORGANIZATION.CREATE_NEW_TEAM')}
          </Button>
        )}
      </Box>
      <Box>
        <TeamsTable
          rows={rows}
          deleteTeam={deleteTeam}
          openEditTeamDialog={() => {
            setIsUpdateDialogOpen(true);
          }}
          selectedTeamId={selectedTeam?.id || ''}
          setSelectedTeam={setSelectedTeam}
          isLoading={isLoading}
          fetchTeamUsers={fetchTeamUsers}
        />
      </Box>
      <AddTeamDialog
        isOpen={isDialogOpen}
        handleClose={handleClose}
        onSubmit={addNewTeam}
        users={users}
      />
      <UpdateTeamDialog
        isOpen={isUpdateDialogOpen}
        handleClose={handleClose}
        onSubmit={updateTeam}
        users={users}
        row={selectedTeam}
        fetchTeamUsers={fetchTeamUsers}
      />
    </Box>
  );
}
