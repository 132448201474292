import {
  Box,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { Theme, useTheme } from '@mui/material/styles';
import { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { User } from '../../../models/user.model';
import { WORDS_ONLY_REGEX } from '../../../utils/regexHelpers';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles($id: string, users: readonly string[], theme: Theme) {
  return {
    fontWeight:
      users.indexOf($id) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export interface CreateTeamInput {
  name: string;
  users: string[];
  managerId: string;
}

export default function AddTeamDialog({
  isOpen,
  handleClose,
  onSubmit,
  users,
}: {
  isOpen: boolean;
  handleClose: () => void;
  onSubmit: SubmitHandler<CreateTeamInput>;
  users: User[];
}) {
  const { t } = useTranslation();
  const theme = useTheme();

  const { reset, control, handleSubmit, getValues } =
    useForm<CreateTeamInput>();

  const onHandleClose = () => {
    reset();
    handleClose();
  };

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  return (
    <Box>
      <Dialog open={isOpen} onClose={onHandleClose}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'contents' }}>
          <DialogTitle>{t('ORGANIZATION.CREATE_NEW_TEAM')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t('ORGANIZATION.CREATE_NEW_TEAM_DESCRIPTION')}
            </DialogContentText>
            <Controller
              name="name"
              control={control}
              defaultValue={''}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  label={t('ORGANIZATION.TEAM.NAME')}
                  helperText={error ? error.message : null}
                  sx={{
                    marginBottom: '20px',
                    marginTop: '20px',
                  }}
                  inputProps={{ 'data-testid': 'teamNameInput' }}
                />
              )}
              rules={{
                required: `${t('ORGANIZATION.TEAM.NAME')} ${t(
                  'ORGANIZATION.TEAM.REQUIRED'
                )}`,

                maxLength: {
                  value: 50,
                  message: t('VALIDATION.MAX_LENGTH', { length: 50 }),
                },

                pattern: {
                  value: WORDS_ONLY_REGEX,
                  message: `${t('VALIDATION.INVALID_VALUE')}`,
                },
              }}
            />
            <Controller
              name="users"
              control={control}
              defaultValue={[]}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl
                  fullWidth
                  error={!!error}
                  sx={{
                    marginBottom: '20px',
                  }}
                >
                  <InputLabel id="demo-simple-select-label">
                    {t('ORGANIZATION.TEAM.USERS')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    data-testid={'userSelect'}
                    value={value}
                    error={!!error}
                    multiple
                    label={t('ORGANIZATION.TEAM.USERS')}
                    onChange={onChange}
                    inputProps={{ 'data-testid': 'usersInput' }}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => {
                          const user = users.find((user) => user.id === value);
                          if (!user) {
                            return <></>;
                          }

                          return (
                            <Chip
                              key={value}
                              label={`${user.firstName} ${user.lastName}`}
                            />
                          );
                        })}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {users
                      .filter((user) => {
                        return user.role.includes('TEAM_MEMBER');
                      })
                      .map((user, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={user.id}
                            style={getStyles(user.id, getValues().users, theme)}
                          >
                            {`${user.firstName} ${user.lastName}`}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <FormHelperText>
                    {error
                      ? `${t('ORGANIZATION.TEAM.USERS')} ${t(
                          'ORGANIZATION.TEAM.REQUIRED'
                        )}`
                      : ''}
                  </FormHelperText>
                </FormControl>
              )}
              rules={{
                required: `${t('ORGANIZATION.TEAM.USERS')} ${t(
                  'ORGANIZATION.TEAM.REQUIRED'
                )}`,
              }}
            />
            <Controller
              name="managerId"
              control={control}
              defaultValue={''}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl
                  fullWidth
                  error={!!error}
                  sx={{
                    marginBottom: '20px',
                  }}
                >
                  <InputLabel id="demo-simple-select-label">
                    {t('ORGANIZATION.TEAM.MANAGER')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    data-testid={'managerSelect'}
                    value={value}
                    error={!!error}
                    label={t('ORGANIZATION.TEAM.MANAGER')}
                    onChange={onChange}
                    inputProps={{ 'data-testid': 'managerIdInput' }}
                  >
                    {users
                      .filter((user) => {
                        return user.role.includes('TEAM_MANAGER');
                      })
                      .map((user, index) => {
                        return (
                          <MenuItem key={index} value={user.id}>
                            {user.firstName} {user.lastName}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <FormHelperText>
                    {error
                      ? `${t('ORGANIZATION.TEAM.MANAGER')} ${t(
                          'ORGANIZATION.TEAM.REQUIRED'
                        )}`
                      : ''}
                  </FormHelperText>
                </FormControl>
              )}
              rules={{
                required: `${t('ORGANIZATION.TEAM.MANAGER')} ${t(
                  'ORGANIZATION.TEAM.REQUIRED'
                )}`,
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onHandleClose} data-testid="cancelDialog">
              {t('ORGANIZATION.TEAM.CANCEL')}
            </Button>
            <Button type="submit" data-testid="createButton">
              {t('ORGANIZATION.TEAM.SUBMIT')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
}
