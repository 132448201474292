import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  Chip,
  IconButton,
  InputAdornment,
  Link,
  Menu,
  MenuItem,
  Snackbar,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';
import Box from '@mui/material/Box';
import { camelCase, debounce, get, mapKeys, pickBy, snakeCase } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import axios from '../../../axios';
import { useAuth } from '../../../context/AuthContext';
import { useNotification } from '../../../context/NotificationContext';
import { Category } from '../../../models/category.model';
import { Product } from '../../../models/product.model';
import doesRoleExist from '../../../utils/doesRoleExist';
import ExportDataDialog from '../ExportDialog';
import AddProductDialog, { AddProductInput } from './AddProductDialog';
import CategoriesDialog from './CategoriesDialog';
import ProductsTable, { Data } from './ProductsTable';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ProductsPage() {
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [filter, setFilter] = useState('');
  const { organization, user, subscription } = useAuth();
  const history = useHistory();
  const [rows, setRows] = React.useState<Data[]>([]);
  const [displayRows, setDisplayRows] = React.useState<Data[]>([]);
  const [isCategoriesDialogOpen, setIsCategoriesDialogOpen] = useState(false);
  const [categories, setCategories] = useState<Category[]>([]);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const confirm = useConfirm();
  const [isExportDataDialogOpen, setExportDataDialogOpen] =
    useState<boolean>(false);

  const { showMessage } = useNotification();

  const [alertProps, setAlertProps] = useState<{
    severity: AlertColor;
    isOpen: boolean;
    text: string;
  }>({
    severity: 'success',
    isOpen: false,
    text: '',
  });

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleUploadClick = () => {
    inputRef.current?.click();
  };

  const fetchCategories = useCallback(async () => {
    const categoriesResp = await axios.get(
      `api/organizations/${organization?.id}/categories`
    );
    const categories = categoriesResp.data.data.items.map((item: Category) => {
      const category = new Category();
      const categoryObj = mapKeys(item, (v, k) => camelCase(k));
      return category.deserialize(categoryObj);
    });
    setCategories(categories);
  }, [organization?.id]);

  const addNewCategory = useCallback(
    async (name: string) => {
      await axios.post(`api/organizations/${organization?.id}/categories`, {
        name,
      });
      fetchCategories();
    },
    [organization?.id, fetchCategories]
  );

  const handleAddCategoryClose = useCallback(() => {
    setIsCategoriesDialogOpen(false);
  }, []);

  const handleClose = useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  const debouncedFilter = useMemo(
    () =>
      debounce((value: string) => {
        if (value === '') {
          setDisplayRows(rows);
        } else {
          setDisplayRows(
            rows.filter((o) =>
              Object.keys(o).some((k) =>
                get(o, k)
                  ?.toString()
                  .toLowerCase()
                  .includes(value.toLowerCase())
              )
            )
          );
        }
      }, 1000),
    [rows]
  );

  const deleteCategory = useCallback(
    (categoryId: string) => {
      confirm({
        title: t('CONFIRMATION.ARE_YOU_SURE'),
        description: t('CONFIRMATION.DESCRIPTION'),
        confirmationText: t('CONFIRMATION.YES'),
        cancellationText: t('CONFIRMATION.CANCEL'),
      })
        .then(async () => {
          await axios.delete(
            `api/organizations/${organization?.id}/categories/${categoryId}`
          );
          fetchCategories();
        })
        .catch(() => {});
    },
    [confirm, fetchCategories, organization?.id, t]
  );

  const fetchProducts = useCallback(async () => {
    setIsLoading(true);
    const productsResp = await axios.get(
      `api/organizations/${organization?.id}/products`
    );
    const productsData = productsResp.data.data.items.map((item: any) => {
      return mapKeys(item, (v, k) => camelCase(k));
    });
    const data = productsData.map((product: Product) => ({
      id: product.id.toString(),
      organizationId: product.organizationId,
      categoryId: product.categoryId,
      name: product.name,
      description: product.description,
      serialNumber: product.serialNumber,
      unit: product.unit,
      unitPrice: product.unitPrice,
      isActive: product.isActive,
    }));
    setRows(data);
    setDisplayRows(data);
    setIsLoading(false);
  }, [organization?.id]);

  const handleFileChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files) {
        return;
      }
      const file = e.target.files[0];

      try {
        const data = new FormData();
        data.append('data', file);
        const { data: res } = await axios.post(
          `api/organizations/${organization?.id}/products/parse-bulk-import-csv`,
          data
        );
        confirm({
          title: t('CONFIRMATION.ARE_YOU_SURE'),
          description: t('CONFIRM_PRODUCTS_IMPORT', {
            productsCount: res.data.data.length,
          }),
          confirmationText: t('CONFIRMATION.YES'),
          cancellationText: t('CONFIRMATION.CANCEL'),
        })
          .then(async () => {
            await axios.post(
              `api/organizations/${organization?.id}/products/bulk`,
              res.data
            );
            fetchProducts();
          })
          .catch(() => {});
      } catch (e) {
        showMessage({
          severity: 'error',
          message: t('IMPORT_PRODUCTS_FAILED'),
        });
      } finally {
        handleMenuClose();
      }
    },
    [handleMenuClose, organization?.id, confirm, t, fetchProducts, showMessage]
  );

  useEffect(() => {
    fetchProducts();
    fetchCategories();
  }, [fetchProducts, fetchCategories]);

  const addNewProduct = useCallback(
    async (data: AddProductInput) => {
      handleClose();
      const dataObj = pickBy(data);
      if (!dataObj.isActive) {
        dataObj.isActive = false;
      }
      await axios.post(
        `api/organizations/${organization?.id}/products`,
        mapKeys(dataObj, (v, k) => snakeCase(k))
      );
      fetchProducts();
    },
    [fetchProducts, organization?.id, handleClose]
  );

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', position: 'relative' }}
      data-testid="productsPage"
    >
      <Box
        sx={{
          flexGrow: 1,
          p: 2,
          display: 'flex',
          justifyContent: 'space-between',
          paddingRight: '0px',
          paddingLeft: '0px',
          flexDirection: isMobile ? 'column' : 'row',
          gap: '12px',
        }}
      >
        <Box>
          <TextField
            placeholder="Search..."
            id="search-product"
            value={filter}
            sx={{ width: '25ch' }}
            inputProps={{ 'data-testid': 'searchInput' }}
            onChange={(event) => {
              setFilter(event.target.value);
              debouncedFilter(event.target.value);
            }}
            InputProps={{
              sx: { height: '45px' },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    data-testid="clearFilter"
                    aria-label="clear"
                    onClick={() => {
                      setFilter('');
                      debouncedFilter('');
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="outlined"
            color="primary"
            sx={{
              maxWidth: 250,
              marginLeft: isMobile ? 0 : 2,
              marginTop: isMobile ? 2 : 0,
              width: isMobile ? 250 : 'fit-content',
            }}
            onClick={() => {
              setIsCategoriesDialogOpen(true);
            }}
            data-testid="createNewCategoryButton"
          >
            {t('CATEGORIES.NAME')}
          </Button>
        </Box>

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem
            onClick={() => {
              handleMenuClose();
              setIsDialogOpen(true);
            }}
          >
            {t('PRODUCTS.CREATE_NEW')}
          </MenuItem>

          <>
            <MenuItem
              disabled={
                !['ecosh-standard', 'ecosh-professional'].includes(
                  subscription?.product
                )
              }
              onClick={() => {
                handleUploadClick();
              }}
            >
              {t('IMPORT_PRODUCTS')}&nbsp;
              <Chip size="small" label="Standard" />
            </MenuItem>
            <input
              type="file"
              ref={inputRef}
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />
          </>

          {['ecosh-standard', 'ecosh-professional'].includes(
            subscription?.product
          ) && (
            <Link
              style={{ color: 'inherit', textDecoration: 'inherit' }}
              href={`${process.env.REACT_APP_API_URL}/api/assets/product-import-template`}
              target="_blank"
              rel="noreferrer"
            >
              <MenuItem onClick={handleMenuClose}>
                {t('DOWNLOAD_IMPORT_TEMPLATE')}
                &nbsp;
                <Chip size="small" label="Standard" />
              </MenuItem>
            </Link>
          )}

          <MenuItem
            disabled={!['ecosh-professional'].includes(subscription?.product)}
            onClick={() => {
              handleMenuClose();
              setExportDataDialogOpen(true);
            }}
          >
            {t('EXPORTS.PRODUCTS')}
            &nbsp;
            <Chip size="small" label="Professional" />
          </MenuItem>
        </Menu>

        {doesRoleExist(user, organization, ['ADMIN']) && (
          <Button
            variant="outlined"
            sx={{
              maxWidth: 250,
            }}
            onClick={handleClick}
            data-testid="createNewProductButton"
          >
            {t('EDIT_PRODUCTS')}
          </Button>
        )}
      </Box>
      <Box>
        <ProductsTable
          rows={displayRows}
          setSelectedProduct={(productId: string) => {
            history.push(`./product/${productId}`);
          }}
          isLoading={isLoading}
          categories={categories}
        />
      </Box>
      <AddProductDialog
        isOpen={isDialogOpen}
        handleClose={handleClose}
        onSubmit={addNewProduct}
        categories={categories}
      />
      <CategoriesDialog
        isOpen={isCategoriesDialogOpen}
        handleClose={handleAddCategoryClose}
        addNewCategory={addNewCategory}
        categories={categories}
        deleteCategory={deleteCategory}
      />

      <ExportDataDialog
        open={isExportDataDialogOpen}
        type="products"
        onClose={(status: boolean) => {
          setExportDataDialogOpen(false);
          if (status) {
            setAlertProps({
              severity: 'success',
              text: 'EXPORTS.COMPLETED',
              isOpen: true,
            });
          }
        }}
      />

      <Snackbar
        open={alertProps.isOpen}
        autoHideDuration={6000}
        onClose={() => {
          setAlertProps((prev) => ({
            ...prev,
            isOpen: false,
          }));
        }}
      >
        <Alert
          onClose={() => {
            setAlertProps((prev) => ({
              ...prev,
              isOpen: false,
            }));
          }}
          severity={alertProps.severity}
          sx={{ width: '100%' }}
          data-testid="alertId"
        >
          {t(alertProps.text)}
        </Alert>
      </Snackbar>
    </Box>
  );
}
