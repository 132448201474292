import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import TasksTable, { Data } from './TasksTable';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import axios from '../../../axios';
import { useHistory } from 'react-router-dom';
import AddTaskDialog, { AddTaskInput } from './AddTaskDialog';
import { camelCase, mapKeys, snakeCase, pickBy, sortBy } from 'lodash';
import { useAuth } from '../../../context/AuthContext';
import { debounce, get } from 'lodash';
import { Task } from '../../../models/task.model';
import { Customer } from '../../../models/customer.model';
import { Team } from '../../../models/team.model';
import doesRoleExist from '../../../utils/doesRoleExist';

export default function TasksPage() {
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [filter, setFilter] = useState('');
  const { organization, user } = useAuth();
  const history = useHistory();
  const [rows, setRows] = React.useState<Data[]>([]);
  const [displayRows, setDisplayRows] = React.useState<Data[]>([]);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [teams, setTeams] = useState<Team[]>([]);

  const handleClose = useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  const debouncedFilter = useMemo(
    () =>
      debounce((value: string) => {
        if (value === '') {
          setDisplayRows(rows);
        } else {
          setDisplayRows(
            rows.filter((o) =>
              Object.keys(o).some((k) =>
                get(o, k)?.toLowerCase().includes(value.toLowerCase())
              )
            )
          );
        }
      }, 1000),
    [rows]
  );

  const fetchTasks = useCallback(async () => {
    setIsLoading(true);
    const tasksResp = await axios.get(
      `api/organizations/${organization?.id}/tasks`
    );
    const tasksData = tasksResp.data.data.items.map((item: any) => {
      item.customer = mapKeys(item.customer, (_v, k) => camelCase(k));
      return mapKeys(item, (_v, k) => camelCase(k));
    });
    const data = tasksData.map((task: Task) => ({
      id: task.id.toString(),
      title: task.title,
      dueDate: task.dueDate,
      priority: task.priority,
      status: task.status,
      customerName: task.customer.shortName,
    }));
    setRows(data);
    setDisplayRows(data);
    setIsLoading(false);
  }, [organization?.id]);

  useEffect(() => {
    const fetchData = async () => {
      const customersResp = await axios.get(
        `api/organizations/${organization?.id}/customers`
      );
      setCustomers(
        sortBy(
          customersResp.data.data.items.map((item: any) => {
            const customerObj = mapKeys(item, (_v, k) => camelCase(k));
            const customer = new Customer();
            return customer.deserialize(customerObj);
          }),
          ['shortName']
        )
      );

      const teamsResp = await axios.get(
        `api/organizations/${organization?.id}/teams`
      );
      const teamsData = teamsResp.data.data.items.map((item: any) => {
        item.manager = mapKeys(item.manager, (_v, k) => camelCase(k));
        return mapKeys(item, (_v, k) => camelCase(k));
      });
      setTeams(
        teamsData.map((item: any) => {
          const teamObj = mapKeys(item, (_v, k) => camelCase(k));
          const team = new Team();
          return team.deserialize(teamObj);
        })
      );
    };
    fetchData();
  }, [organization?.id]);

  useEffect(() => {
    fetchTasks();
  }, [fetchTasks]);

  const addNewTask = useCallback(
    async (data: AddTaskInput) => {
      handleClose();
      await axios.post(
        `api/organizations/${organization?.id}/tasks`,
        mapKeys(pickBy(data), (_v, k) => snakeCase(k))
      );
      fetchTasks();
    },
    [fetchTasks, organization?.id, handleClose]
  );

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', position: 'relative' }}
      data-testid="tasksPage"
    >
      <Box
        sx={{
          flexGrow: 1,
          p: 2,
          display: 'flex',
          justifyContent: 'space-between',
          paddingRight: '0px',
          paddingLeft: '0px',
          flexDirection: isMobile ? 'column' : 'row',
          gap: '12px',
        }}
      >
        <TextField
          placeholder="Search..."
          id="search-task"
          value={filter}
          sx={{ width: '25ch' }}
          inputProps={{ 'data-testid': 'searchInput' }}
          onChange={(event) => {
            setFilter(event.target.value);
            debouncedFilter(event.target.value);
          }}
          InputProps={{
            sx: { height: '45px' },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  data-testid="clearFilter"
                  aria-label="clear"
                  onClick={() => {
                    setFilter('');
                    debouncedFilter('');
                  }}
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {doesRoleExist(user, organization, [
          'ADMIN',
          'TEAM_MANAGER',
          'TEAM_MEMBER',
        ]) && (
          <Button
            variant="outlined"
            sx={{
              maxWidth: 250,
            }}
            onClick={() => {
              setIsDialogOpen(true);
            }}
            data-testid="createNewTaskButton"
          >
            {t('TASKS.CREATE_NEW_TASK')}
          </Button>
        )}
      </Box>
      <Box>
        <TasksTable
          rows={displayRows}
          setSelectedTask={(taskId: string) => {
            history.push(`./task/${taskId}`);
          }}
          isLoading={isLoading}
        />
      </Box>
      <AddTaskDialog
        isOpen={isDialogOpen}
        handleClose={handleClose}
        onSubmit={addNewTask}
        customers={customers}
        teams={teams}
      />
    </Box>
  );
}
