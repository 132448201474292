import { Box, CircularProgress, Typography } from '@mui/material';
import { useMemo, useState } from 'react';

import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

const fileTypes = {
  'image/png': ['.png'],
  'text/csv': ['.csv'],
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
    '.docx',
  ],
  'image/jpeg': ['.jpeg', '.jpg'],
  'application/pdf': ['.pdf'],
  'application/vnd.ms-powerpoint': ['.ppt'],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': [
    '.pptx',
  ],
  'application/vnd.rar': ['.rar'],
  'application/zip': ['.zip'],
  'image/svg+xml': ['.svg'],
  'text/plain': ['.txt'],
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
    '.xlsx',
  ],
};

const baseStyle = {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: 'gray',
  borderStyle: 'dashed',
  justifyContent: 'center',
  backgroundColor: '#fafafa',
  color: 'gray',
  outline: 'none',
  cursor: 'pointer',
  transition: 'border .24s ease-in-out',
};

const focusedStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

export default function FileUpload({
  onUploadHandler,
}: {
  onUploadHandler: (file: File) => {};
}) {
  const [files, setFiles] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: fileTypes,
      onDrop: async (acceptedFiles: File[]) => {
        setFiles(acceptedFiles);
        setIsLoading(true);
        await onUploadHandler(acceptedFiles[0]);
        setIsLoading(false);
      },
      maxFiles: 1,
    });

  const { t } = useTranslation();
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <Box {...getRootProps({ style })} display="flex" flexDirection="column">
      <input {...getInputProps()} />
      {isLoading && (
        <>
          <CircularProgress />
          <Typography variant="subtitle1">
            {files.map((file) => file.name)}
          </Typography>
        </>
      )}
      {!isLoading && (
        <Typography variant="subtitle1">
          {t('ATTACHMENTS.UPLOAD_FILE')}
        </Typography>
      )}
    </Box>
  );
}
